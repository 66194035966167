import React, { Component } from "react";
import { NavLink as Link} from "react-router-dom";
import { upperCaseOfName  } from '../../helpers/common';
import { translate, formatDate } from './../../helpers/intl_helpers';
import { DEPARTMENT_DROPDOWN, JOB_TITLE_TYPE_DROPDOWN, DATERANGE_DATE_TIME_FORMAT, ACTIVE, PROJECT_MANAGER, FOREMAN  } from '../../config/global_constants';

import callIcon 	from '../../assets/images/call-icon.svg';
import mailIcon 	from '../../assets/images/mail-icon.svg';
import editIcon 	from '../../assets/images/edit-icon.svg';

export default class EmployeeDetailModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('employeeDetailModal');
	}// end closeModal()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}


	render() {
		const { profileDetails, fileUrl, noImgUrl, authPermission } = this.props;
		return (
			<div className="modal fade crew-modal" id="employeeDetailModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>
						<div className="modal-body">
							<div className="profiledetail-head">
								<h3>{profileDetails.full_name ? profileDetails.full_name : translate('calendar.details')}</h3>
								<span>{profileDetails.job_title ? profileDetails.job_title : "N/A"}</span>
							</div>
							<div className="list-group list-group-flush">
								<ul className="details-listing">
									<li>
										<div className="profile-detail-box">
											<span className={`sort-name ${(!profileDetails.image) ? 'greenbg' : ''}`}>
												{profileDetails && profileDetails.image ? (
													<img src={`${fileUrl}${profileDetails.image}`} alt="employee" onError={(event) => event.target.src = noImgUrl} onClick={() => this.handleImageClick(`${fileUrl}${profileDetails.image}`)} />
												) : (
													upperCaseOfName(profileDetails.first_name ? profileDetails.first_name : "", profileDetails.last_name ? profileDetails.last_name : "")
												)}
											</span>
											<div>
												<div className="inner-wrap">
													<p>{translate('employee.employee_number')}</p>
													<span>{profileDetails.employee_number ? profileDetails.employee_number : "N/A"}</span>
													<ul className="d-flex align-items-center termination-list">
														{profileDetails.active ?
															<li>
																<span className="">{profileDetails.hire_date ? formatDate(profileDetails.hire_date , DATERANGE_DATE_TIME_FORMAT) : "N/A"}</span>
															</li>
														: <li>
															<span className="">{profileDetails.termination_date ? formatDate(profileDetails.termination_date , DATERANGE_DATE_TIME_FORMAT) : "N/A"}</span>
															</li>
														}
														<li>
															<span className={profileDetails.active ? "text-success" : "text-danger"}>
																{profileDetails.active ? translate("employee.active") : translate("employee.terminated")}
															</span>
														</li>

													</ul>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('employee.company_name')}</p>
											<span>{profileDetails.company_name_title ? profileDetails.company_name_title : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('employee.email')}</p>
											<div>
												<img src={mailIcon} alt="img" width={19} height={21} />
												{profileDetails.email ? (
													<a href={`mailto:${profileDetails.email}`} className="text-lowercase email-link">
														{profileDetails.email}
													</a>
												) : "N/A"}
											</div>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.mobile')}</p>
											<div>
												<img src={callIcon} alt="img" width={19} height={21} />
												{profileDetails.mobile ? (
													<a href={`tel:${profileDetails.mobile}`}>{profileDetails.mobile}</a>
												) : "N/A"}
											</div>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('employee.location')}</p>
											<span>{profileDetails.location_title ? profileDetails.location_title : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('employee.department')}</p>
											<span>
												{(profileDetails.department && profileDetails.department.length) &&
													DEPARTMENT_DROPDOWN.filter(department => profileDetails.department.includes(department.label.toLowerCase()))
													.map(department => department.label)
													.join(',')
												}
											</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('employee.assigned_vehicle')}</p>
											<span>{profileDetails.assigned_vehicle ? profileDetails.assigned_vehicle : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('employee.job_title_type')}</p>
											<span>{JOB_TITLE_TYPE_DROPDOWN.find(item => item.value === profileDetails.job_title_type)?.label || "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('employee.driver_tier_level')}</p>
											<span>{profileDetails.driver_tier_level ? profileDetails.driver_tier_level : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('employee.bpid')}</p>
											<span className="text-transform-none">{profileDetails.bpid ? profileDetails.bpid : "N/A"}</span>
										</div>
									</li>
								</ul>
							</div>
							{(authPermission !== PROJECT_MANAGER && authPermission !== FOREMAN) ?
								<div className="detail-modal-editbtns">
									<Link to={"/employees/update/"+profileDetails._id} onClick={()=> {this.closeModal()} }>
										<img src={editIcon} alt="img" width={21} height={21} />
										Edit
									</Link>
								</div>
							:null}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
