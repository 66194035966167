import React, { Component } from "react";
import { NavLink as Link} from "react-router-dom";
import { translate } from './../../helpers/intl_helpers';
import { upperCaseOfName  } from '../../helpers/common';
import { DEPARTMENT_DROPDOWN, ACTIVE  } from '../../config/global_constants';

import editIcon 	from '../../assets/images/edit-icon.svg';

export default class EquipmentDetailModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('equipmentDetailModal');
	}// end closeModal()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}


	render() {
		const { profileDetails, fileUrl, noImgUrl } = this.props;
		return (
			<>
			<div className="modal fade crew-modal" id="equipmentDetailModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>
						<div className="modal-body">
							<h3>{profileDetails.equipment_code ? profileDetails.equipment_code : translate('calendar.details')}</h3>
							<div className="list-group list-group-flush">
								<ul className="details-listing">
									<li>
										<div className="profile-detail-box">
											<span className={`sort-name ${(!profileDetails.image) ? 'greenbg' : ''}`}>
												{profileDetails && profileDetails.image ? (
													<img src={`${fileUrl}${profileDetails.image}`} alt="employee" onError={(event) => event.target.src = noImgUrl} onClick={() => this.handleImageClick(`${fileUrl}${profileDetails.image}`)} />
												) : (
													upperCaseOfName(profileDetails.first_name ? profileDetails.first_name : "", profileDetails.last_name ? profileDetails.last_name : "")
												)}
											</span>
											<div>
												<div className="inner-wrap">
													<p>{translate('employee.equipment_code')}</p>
													<span>{profileDetails.equipment_code ? profileDetails.equipment_code : "N/A"}</span>
													<span className={profileDetails.is_active ? "text-success" : "text-danger"}>
														{profileDetails.is_active ? "Active" : "Deactive"}
													</span>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.equipment_type')}</p>
											<span>{profileDetails.equipment_type ? profileDetails.equipment_type : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.description')}</p>
											<span>{profileDetails.description ? profileDetails.description : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.equipment_make_model')}</p>
											<span>{profileDetails.equipment_make && profileDetails.equipment_model  ? profileDetails.equipment_make +' / '+ profileDetails.equipment_model : profileDetails.equipment_make ? profileDetails.equipment_make : profileDetails.equipment_model ? profileDetails.equipment_model : ""}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.location')}</p>
											<span>{profileDetails.location_title ? profileDetails.location_title : ""}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.department')}</p>
											<span>
												{(profileDetails.department && profileDetails.department.length) &&
													DEPARTMENT_DROPDOWN.filter(department => profileDetails.department.includes(department.label.toLowerCase()))
													.map(department => department.label)
													.join(',')
												}
											</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('employee.assignee_name')}</p>
											<span>{profileDetails.assignee_name ? profileDetails.assignee_name : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.is_rental')}</p>
											<span>{profileDetails.is_rental === ACTIVE ? "Yes" : "No"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.company_code')}</p>
											<span>{profileDetails.company_code ? profileDetails.company_code : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.tier_level')}</p>
											<span>{profileDetails.tier_name ? profileDetails.tier_name : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.year')}</p>
											<span>{profileDetails.year ? profileDetails.year : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.serial_number')}</p>
											<span>{profileDetails.serial_number ? profileDetails.serial_number : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.license_number')}</p>
											<span>{profileDetails.license_number ? profileDetails.license_number : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('equipments.gvw_class')}</p>
											<span>{profileDetails.gvw_class ? profileDetails.gvw_class : "N/A"}</span>
										</div>
									</li>
								</ul>
							</div>
						    {/* Set edit button for update */}
							<div className="detail-modal-editbtns">
								<Link to={"/equipments/update/"+profileDetails._id} onClick={()=> {this.closeModal()} }>
									<img src={editIcon} alt="img" width={21} height={21} />
									Edit
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}
}
