
import React, {Component} from 'react'
import InfiniteScroll from "react-infinite-scroll-component";

import clock from '../../../assets/images/clock.svg';
import date from '../../../assets/images/date.svg';
import user from '../../../assets/images/user_icon.svg';
import edit from '../../../assets/images/edit_mode_icon.svg';
import deleteIcon from '../../../assets/images/delete-icon.svg';

import { translate, formatDate, formatTime } from '../../../helpers/intl_helpers'
import { STATUS_SUCCESS, AM_PM_FORMAT_WITH_TIME, DATERANGE_DATE_TIME_FORMAT } from '../../../config/global_constants'
import { handleFormValidation, handleFormInputs,  callBackendAPI, customConfirmBox, reFormatSuccess, convertUrlsToLinks } from '../../../helpers/common';

export default class NoteBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors      :   {},
            fields      :   {},
            submitted   :   false,

            notesList   :   [],
            totalRecords: 	0,
        };
        this.isMountedFlag = false;
        this.handleFormInputs 		=	this.handleFormInputs.bind(this);
		this.handleNotesFormSubmit 	=  	this.handleNotesFormSubmit.bind(this);
    }

    componentDidMount() {
        this.isMountedFlag = true;
        this.getNotesList(true);
    }

    componentWillUnmount() {
        this.isMountedFlag = false; // Clear the flag when the component unmounts
    }

    /**
	* For get notes listing
	*/
	getNotesList(freshListing) {
        const {notesList } = this.state;

        let tmpList   =   !freshListing && notesList ? notesList :[];
        let skip      =   !freshListing ? tmpList.length :0;

        callBackendAPI([{ model: 'home', method: 'getNotes',skip: skip, job_id: this.props.job_id ? this.props.job_id :"", job_group_id: this.props.job_group_id ? this.props.job_group_id :"" }]).then(response => {
            if(this.isMountedFlag && response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
                let updatedAssetList =  tmpList.concat(response.data[0].result);
                this.setState({
                    notesList 	:	updatedAssetList,
                    totalRecords: 	response.data[0].recordsTotal
                });
            }
        }).catch(err => console.log(err));
    }

    /**
	* For manage form submit event
	*
	* @param e as current form object
	*
	* @return null
	*/
	handleNotesFormSubmit(e){
		e.preventDefault();

		this.setState({submitted: true},()=>{

            const { description, note_id } = this.state.fields;

            /** Api Requested Data **/
            let params = [{
                model		: 	'home',
                method		: 	'addNotes',
                job_id      :   this.props.job_id ? this.props.job_id :"",
                job_group_id:   this.props.job_group_id ? this.props.job_group_id :"",
                note_id     : 	note_id	? note_id :"",
                description : 	(description && description.value)	? description.value :"",

            }];

            /** Validation Rules **/
            let validations = {description	: {"label": translate('notes.description'), "rules" :["required"]}}

            /** Handle validation and api response **/
            handleFormValidation(validations,this.state.fields, params,true , true, true ).then(response =>{
                this.setState({submitted: false});
                if(!response.success) return this.setState({ errors : response.errors});

                this.getNotesList(true);

                /** Remove disable attribute from submit button */
                this.setState({ errors: {} , fields: {} , submitted: false});

            }).catch(err => console.log(err));
        });
	}

    /**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

    /**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        this.setState({ submitted: false, errors: {}, fields: {} });
        window.closeModal('notesModal');
	}// end closeModal()

    /**
	* For update state fields values
	*
	* @param e     as current field object
	* @param data  as current row object
	*
	* @return null
	*/
    async deleteNotes(e, data){
        e.preventDefault();

        if(data && data._id){
            if(await customConfirmBox(translate('notes.notes_delete_confirm_message'))){
                window.showLoader();
                callBackendAPI([{ model : "home", method : "deleteNote",note_id : data._id}]).then(response=>{
                    window.hideLoader();
                    if(this.isMountedFlag && response.success && response.data[0].message && response.data[0].status === STATUS_SUCCESS){
                        reFormatSuccess(response.data[0].message);

                        this.getNotesList(true);
                    }
                }).catch(err => console.log(err));
            }
        }

    }// end deleteNotes()

    /**
	* For Edit
	*
	* @param e     as current field object
	* @param data  as current row object
	*
	* @return null
	*/
	openEditMode(e, data){
        this.setState({ fields: {
            note_id     : data._id,
            description : {value: data.description ? data.description :""},
        }}, ()=>{
            window.scrollTo(-100, 0);
        });
	}// end openEditMode()

    render() {
        const {submitted, fields, errors, notesList, totalRecords} 	= this.state;
        const {job_id} 	= this.props;

        return (
            <>
                <h3>{fields.note_id ? translate("notes.update_note") :translate("notes.add_note")}</h3>
                <form className="add-job-title-form" onSubmit={(event) => this.handleNotesFormSubmit(event)} noValidate>
                    <div className='add-job-group'>
                        <div className="form-group form-floating ">
                            <textarea  className="form-control" onChange={(event) => this.handleFormInputs(event, "description")} name='description' id='description' value={fields["description"] ? fields["description"]["value"] : ""}></textarea>
                            <label htmlFor="start_date">{translate("notes.add_note")}<span className="error text-danger">*</span></label>
                            <span className="error text-danger">{errors["description"]}</span>
                        </div>
                        <button type={submitted ? "button" :"submit"} className="btn btn-fill width-initial mt-0">
                            {translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
                        </button>
                        {fields.note_id ?
                            <button type='button' className="btn btn-fill width-initial mt-0 ms-1" onClick={()=>{ this.setState({ errors: {} , fields: {} , submitted: false}); }}>
                                {translate("system.reset")}
                            </button>
                        :null}
                    </div>
                </form>
                {notesList && notesList.length ?
                    <div className='add-job-group mt-3'>
                        <div className='notes-list' id={job_id ? "jobNotesScrollableDiv" : "notesScrollableDiv" } >
                            <InfiniteScroll
                                key={notesList.length}
                                dataLength={notesList.length}
                                next={() => { this.getNotesList() }}
                                hasMore={(totalRecords && totalRecords > notesList.length) ? true :false}
                                scrollableTarget={job_id ? "jobNotesScrollableDiv" : "notesScrollableDiv" }
                            >
                                <ul>
                                    {notesList.map((item, index) => (
                                        <li key={'note-modal-'+index}>
                                            <div className='notes-body'>
                                                <p className='nl2br'><span dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(item.description || "") }} /></p>
                                            </div>
                                            <div className='notes-footer'>
                                                <span><img src={clock} alt="clock" width={16} height={16}/>{formatTime(item.modified,AM_PM_FORMAT_WITH_TIME)}</span>
                                                <span><img src={date} alt="date" width={16} height={16}/>{formatDate(item.modified,DATERANGE_DATE_TIME_FORMAT)}</span>
                                                <span><img src={user} alt="user" width={16} height={16}/>{item.user_name}</span>
                                                <div className="ms-auto d-flex notes-footerBottom">
                                                    <span className="action-btn mb-0" onClick={(e)=>{ this.openEditMode(e, item) }}>
                                                        <img src={edit} alt="user" width={16} height={16}/>
                                                    </span>
                                                    <span className="action-btn edit-note  mb-0" onClick={(e)=>{ this.deleteNotes(e, item) }}>
                                                        <img src={deleteIcon} alt="user" width={16} height={16}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    </div>
                :null}
            </>
        );
    }
}