import React, { Component } from 'react';
import { Table} from 'react-bootstrap';
import { NavLink as Link} from "react-router-dom";
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import {AsyncPaginate} from 'react-select-async-paginate';

import { callBackendAPI, handleFormInputs, customFixedValue, setOrGetCookiesValue} from './../../helpers/common';
import { translate, formatDate, getCurrentWeekDates } from './../../helpers/intl_helpers';
import { STATUS_SUCCESS, DATE_RANGE_PICKER_FORMAT, DEPARTMENT_DROPDOWN, FIBER_DEPARTMENT, EXPORT_CSV, EXPORT_PDF, CALENDAR_DATE_TIME_FORMAT, CURRENCY_SYMBOL } from './../../config/global_constants';

import closeIcon 	from './../../assets/images/close_icon.svg';
import dataTable 	from './../../assets/images/data-table.svg';
import dataAsc 		from './../../assets/images/data-asc.svg';
import dataDesc 	from './../../assets/images/data-desc.svg';
import arrowDown    from './../../assets/images/arrow_down_blue.svg';
import filterImg 	from './../../assets/images/filter.svg';
import loaderGif 	from './../../assets/images/pagination_loader.gif';

class JobLookup extends Component {

	constructor(props) {
        super(props);

		let startDate	=	getCurrentWeekDates().monday;
		let endDate		=	getCurrentWeekDates().friday;

		/** Get search stats from cookies  */
		let tmpSearchStats = {date_range :[startDate, endDate]};
		let tmpVal =  setOrGetCookiesValue('searchStatsJobLookupReport');
		if(tmpVal && tmpVal.constructor === Object && Object.keys(tmpVal).length){
			/** Convert into date format */
			if(tmpVal.date_range && tmpVal.date_range.length) tmpVal.date_range =	[new Date(tmpVal.date_range[0]), new Date(tmpVal.date_range[1])];

			tmpSearchStats = tmpVal;
		}

        this.state = {
			defaultDateRange: 	[startDate, endDate],
			searchStats 	: 	tmpSearchStats,
            jobList			: 	[],
			isLoading		:	true,
			locationList	:	[],
			sortStats		:	{},
        };

		this.getJobList    			=	this.getJobList.bind(this);
		this.handleChangePage    	=	this.handleChangePage.bind(this);
		this.handleFormInputs 		=	this.handleFormInputs.bind(this);
		this.resetSearchStats    	=	this.resetSearchStats.bind(this);
		this.getMasterList    		=	this.getMasterList.bind(this);
		this.getDropDownList        =   this.getDropDownList.bind(this);
		this.exportTableContent		=	this.exportTableContent.bind(this);
		this.manageSortingEvent		=	this.manageSortingEvent.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to call initial function.
	 */
	componentDidMount() {
		this.getMasterList();
		this.getJobList();
    }

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			let apiReq = { model: 'calendar', method: 'employeeList', emp_name: inputValue ? inputValue : ''};
            if(type === "projectManager")  	apiReq.is_project_manager   =   true;
            if(type === "foreMan")  		apiReq.is_foreman   		=   true;
            if(type === "crewMember")  		apiReq.is_crew_member   	=   true;
            if(type === "subcontractor")  	apiReq.is_subcontractor 	=   true;

			if(type === "jobNumber") apiReq = {model: 'calendar', method: 'cannonJobList', job_number: inputValue ? inputValue : ''};

			if(type === "workOrder"){
				let jobId = this.state.searchStats.job_number && this.state.searchStats.job_number.value ? this.state.searchStats.job_number.value : '';

				apiReq = {model: 'calendar', method: 'workOrderList', job_id: jobId, work_order_number: inputValue ? inputValue : '', is_job_report: true };
			}

			if(type === "fiberTask") apiReq = { model: 'home', method: 'masterData', type: "fiber_task", name: inputValue ? inputValue : ''};
			if(type === "asset") 	 apiReq	= { model: 'calendar', method: 'assetsList', asset_name: inputValue ? inputValue : ''};

			/** Add page number */
            apiReq.current_page = page;

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();

		this.setState({currentPage: newPage},()=>{
			this.getJobList();
		});
	};

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({searchStats: {date_range: this.state.defaultDateRange}, currentPage: 1 },()=>{
			this.getJobList();
		});
	};

	/**
	 * Manage sort event
	 */
	manageSortingEvent = (event, field, dir) => {
		event.preventDefault();

		this.setState({
			sortStats	: {key: field, dir: dir},
			currentPage	: 1,
		},()=>{
			this.getJobList();
		});
	};

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.searchStats);
		this.setState({response});
	}// end handleFormInputs()

	/**
	* For update state fields values
	*
	* @param fieldName	as current field name
	* @param fieldVal  	as current field value
	*
	* @return null
	*/
	handleChange = (fieldName) => (fieldVal) => {
		let newObj = {[fieldName]: fieldVal};
		let updatedFields = {...this.state.searchStats, ...newObj};

		if(fieldName === "location" && fieldVal === 0) 	updatedFields['department'] = null;
		if(fieldName === "job_number") 	updatedFields['work_order_number'] = null;

		this.setState({searchStats: updatedFields});
	};

	/**
	* For get master listing
	*/
	getMasterList = () => {
		window.showLoader();
		callBackendAPI([{ model: 'home', method: 'masterList' , type: ["location"]}]).then(response => {
			window.hideLoader();
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					isLoading	: false,
					locationList: response.data[0].result.location,
				});
			}
		}).catch(err => console.log(err));
	}

	/**
	* For get job listing
	*/
	getJobList = () => {
		const {searchStats, sortStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{

			/** Set api params */
			let apiReq 	=	{ model: 'reports', method: 'jobList', jobLookup: true, allRecords: true};
			if(sortStats && sortStats.key) apiReq = {...apiReq, ...{sort_by: sortStats.key, sort_direction: sortStats.dir} };
			if(searchStats && Object.keys(searchStats).length){
				apiReq.from_date 			=	searchStats.date_range && searchStats.date_range[0] ? formatDate(searchStats.date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
				apiReq.to_date 				=	searchStats.date_range && searchStats.date_range[1] ? formatDate(searchStats.date_range[1],CALENDAR_DATE_TIME_FORMAT) :"";
				apiReq.foreman_id 			=	searchStats.foreman_id ? searchStats.foreman_id.value :"";
				apiReq.employee_id 			=	searchStats.employee_id ? searchStats.employee_id.value :"";
				apiReq.asset_id 			=	searchStats.asset_id ? searchStats.asset_id.value :"";
				apiReq.project_manager_id	=	searchStats.project_manager_id ? searchStats.project_manager_id.value :"";
				apiReq.work_order_id 		=	searchStats.work_order_number ? searchStats.work_order_number.value :"";
				apiReq.job_number_id 		=	searchStats.job_number ? searchStats.job_number.value :"";
				apiReq.fiber_task 			=	searchStats.fiber_task ? searchStats.fiber_task.value :"";
				apiReq.subcontractor_id 	=	searchStats.subcontractor_id ? searchStats.subcontractor_id.value :"";
				apiReq.city 				=	searchStats.city ? searchStats.city.value :"";
				apiReq.state 				=	searchStats.state ? searchStats.state.value :"";
				apiReq.zip 					=	searchStats.zip ? searchStats.zip.value :"";

				if(searchStats.location && searchStats.location.length) 	apiReq.location	 = searchStats.location.map(element => element.value);
				if(searchStats.department && searchStats.department.length) apiReq.department= searchStats.department.map(element => element.value);
			}

			/** Set search stats in cookies  */
			setOrGetCookiesValue('searchStatsJobLookupReport', true, searchStats);

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						jobList  :	response.data[0].result,
						isLoading: 	false
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getJobList()

	/**
	* For manage export event
	*/
	exportTableContent = (fileType) => {
		const {searchStats } = this.state;

		/** Set loading stats */
		window.showLoader();

		/** Set api params */
		let apiReq = {model: 'reports', method: 'exportJobLookup', jobLookup: true, file_type: fileType};
		if(searchStats && Object.keys(searchStats).length){
			apiReq.from_date 			=	searchStats.date_range && searchStats.date_range[0] ? formatDate(searchStats.date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
			apiReq.to_date 				=	searchStats.date_range && searchStats.date_range[1] ? formatDate(searchStats.date_range[1],CALENDAR_DATE_TIME_FORMAT) :"";
			apiReq.foreman_id 			=	searchStats.foreman_id ? searchStats.foreman_id.value :"";
			apiReq.employee_id 			=	searchStats.employee_id ? searchStats.employee_id.value :"";
			apiReq.asset_id 			=	searchStats.asset_id ? searchStats.asset_id.value :"";
			apiReq.project_manager_id	=	searchStats.project_manager_id ? searchStats.project_manager_id.value :"";
			apiReq.work_order_id 		=	searchStats.work_order_number ? searchStats.work_order_number.value :"";
			apiReq.job_number_id 		=	searchStats.job_number ? searchStats.job_number.value :"";
			apiReq.fiber_task 			=	searchStats.fiber_task ? searchStats.fiber_task.value :"";
			apiReq.subcontractor_id 	=	searchStats.subcontractor_id ? searchStats.subcontractor_id.value :"";
			apiReq.city 				=	searchStats.city ? searchStats.city.value :"";
			apiReq.state 				=	searchStats.state ? searchStats.state.value :"";
			apiReq.zip 					=	searchStats.zip ? searchStats.zip.value :"";

			if(searchStats.location && searchStats.location.length) 	apiReq.location	 = searchStats.location.map(element => element.value);
			if(searchStats.department && searchStats.department.length) apiReq.department= searchStats.department.map(element => element.value);
		}

		/** get data table data list */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					const link 		=	document.createElement('a');
					link.href 		=	response.data[0].file_url;
					link.rel 		=	'noopener noreferrer'; // Security for '_blank' links
					document.body.appendChild(link); // Required for Firefox
					link.click();
					document.body.removeChild(link);
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportTableContent()

	/**
	* For manage total in table footer
	*/
	getFooterTotalValue(key){
		let temTotal = 0;
		const {jobList} = this.state;
		if(jobList && jobList.length){
			jobList.map(element=>
				temTotal += element[key] >0 ? element[key] :0
			)
		}
		if(key=== "daily_project_value" || key=== "total_cost") return CURRENCY_SYMBOL+" "+customFixedValue(temTotal);
		return customFixedValue(temTotal);
	}

	render() {
		const {jobList, searchStats, isLoading,locationList, sortStats} = this.state;

		return <>
			<div className="dashboard-content min-height-72vh">
				<div className="dashboard-heading d-flex justify-content-between align-items-center">
					<div className="left-heading">
						<h1>{translate("reports.job_cost_summary")}</h1>
					</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter "  data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="collapseOne">
							<img src={filterImg} alt="Img" width={29} height={26} />
						</Link>
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="reportJobAccordionExample">
						<div className="accordion-item">
							<div id="reportJobCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#reportJobAccordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="reportJobCollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="work_order_number">{translate('reports.date')}</label>
													<div className="col-12">
														<DateRangePicker
															value={searchStats.date_range ? searchStats.date_range :null}
															format={DATE_RANGE_PICKER_FORMAT}
															size="lg"
															className='form-control input-with-label'
															editable={false}
															onChange={this.handleChange("date_range")}
															placeholder={translate('reports.search_by_date')}
															onClean={()=>{ this.handleChange("date_range",[]) }}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-2">
												<div className='form-group'>
													<label className='mb-1 form-label' htmlFor="search_by_location">{translate('reports.location')}</label>
													<Select
														className="custom-react-select"
														inputId="search_by_location"
														value={searchStats["location"] ? searchStats["location"] :null}
														options={locationList}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														isClearable={true}
														isMulti
														placeholder={translate('reports.search_by_location')}
													/>
												</div>
											</div>
											<div className="col-lg-2">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_department">{translate('reports.department')}</label>
													<Select
														className="custom-react-select"
														options={(searchStats.location && Array.isArray(searchStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
															searchStats.location.some(loc => loc.department && loc.department.includes(dept.value))
														) : []}
														closeMenuOnSelect={true}
														isMulti
														placeholder={translate("calendar.all_department")}
														value={searchStats.department ? searchStats.department :null}
														onChange={this.handleChange("department")}
														isClearable={true}
													/>
												</div>
											</div>
											<div className="col-lg-2">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="job_number">{translate('reports.job_number')}</label>
													<AsyncPaginate
														className="custom-react-select"
														value={searchStats.job_number  ? searchStats.job_number : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("job_number")}
														additional={{ page: 1, type: "jobNumber"}}
														isClearable={true}
														placeholder={translate('reports.search_by_job_number')}
													/>
												</div>
											</div>
											<div className="col-lg-2">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="work_order_number">{translate('reports.work_order_number')}</label>
													<AsyncPaginate
														className="custom-react-select"
														value={searchStats.work_order_number  ? searchStats.work_order_number : null}
														key={searchStats.job_number  ? searchStats.job_number.value : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("work_order_number")}
														additional={{ page: 1, type: "workOrder"}}
														isClearable={true}
														placeholder={translate('reports.search_by_work_order_number')}
													/>
												</div>
											</div>
											<div className="col-lg-3 align-self-center">
												<div className="advanced-btn reports_advanced_filter">
                                                    <button className='d-flex align-items-center w-100 btn' type="button" data-bs-toggle="collapse" data-bs-target="#advanced-filter" aria-expanded="false" aria-controls="advanced-filter">
                                                    Advanced Filter <img src={arrowDown} alt="img" />
                                                    </button>
                                                </div>
											</div>
											<div className="col-lg-12 collapse mt-3" id="advanced-filter">
												<div className="row">
													<div className="col-lg-4">
														<div className="form-group">
															<label className='mb-1 form-label' htmlFor="project_manager_id">{translate('reports.project_manager')}</label>
															<AsyncPaginate
																className="custom-react-select"
																inputId="project_manager_id"
																value={searchStats.project_manager_id  ? searchStats.project_manager_id : null}
																loadOptions={this.getDropDownList}
																onChange={this.handleChange("project_manager_id")}
																additional={{ page: 1, type: "projectManager"}}
																isClearable={true}
																placeholder={translate('reports.search_by_project_manager')}
															/>
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<label className='mb-1 form-label' htmlFor="search_foreMan">{translate('reports.foreman')}</label>
															<AsyncPaginate
																className="custom-react-select"
																inputId="search_foreMan"
																value={searchStats.foreman_id  ? searchStats.foreman_id : null}
																loadOptions={this.getDropDownList}
																onChange={this.handleChange("foreman_id")}
																additional={{ page: 1, type: "foreMan"}}
																isClearable={true}
																placeholder={translate('reports.search_by_foreman')}
															/>
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<label className='mb-1 form-label' htmlFor="search_by_employee">{translate('reports.employee')}</label>
															<AsyncPaginate
																className="custom-react-select"
																inputId="search_by_employee"
																value={searchStats.employee_id  ? searchStats.employee_id : null}
																loadOptions={this.getDropDownList}
																onChange={this.handleChange("employee_id")}
																additional={{ page: 1, type: "crewMember"}}
																isClearable={true}
																placeholder={translate('reports.search_by_employee')}
															/>
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<label htmlFor="search_by_city" className="form-label mb-1 ">{translate('reports.city')}</label>
															<input type="text" className="form-control input-with-label" name='search_by_city' placeholder={translate('reports.search_by_city')} id='search_by_city' value={searchStats["city"] ? searchStats["city"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"city")} />
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<label htmlFor="search_by_state" className="form-label mb-1 ">{translate('reports.state')}</label>
															<input type="text" className="form-control input-with-label" name='search_by_state' placeholder={translate('reports.search_by_state')} id='search_by_state' value={searchStats["state"] ? searchStats["state"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"state")} />
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<label htmlFor="search_by_zip" className="form-label mb-1 ">{translate('reports.zip')}</label>
															<input type="text" className="form-control input-with-label" name='search_by_city' placeholder={translate('reports.search_by_zip')} id='search_by_zip' value={searchStats["zip"] ? searchStats["zip"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "zip")} />
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<label className='mb-1 form-label' htmlFor="search_by_equipment">{translate('reports.equipment')}</label>
															<AsyncPaginate
																className="custom-react-select"
																inputId="search_by_equipment"
																value={searchStats.asset_id  ? searchStats.asset_id : null}
																loadOptions={this.getDropDownList}
																onChange={this.handleChange("asset_id")}
																additional={{ page: 1, type: "asset"}}
																isClearable={true}
																placeholder={translate('reports.search_by_equipment')}
															/>
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<label className='mb-1 form-label' htmlFor="search_by_subcontractor">{translate('reports.subcontractor')}</label>
															<AsyncPaginate
																className="custom-react-select"
																inputId="search_by_subcontractor"
																value={searchStats.subcontractor_id  ? searchStats.subcontractor_id : null}
																loadOptions={this.getDropDownList}
																onChange={this.handleChange("subcontractor_id")}
																additional={{ page: 1, type: "subcontractor"}}
																isClearable={true}
																placeholder={translate('reports.search_by_subcontractor')}
															/>
														</div>
													</div>
													{searchStats.department && searchStats.department.value === FIBER_DEPARTMENT ?
														<div className="col-lg-4">
															<div className="form-group">
																<label className='mb-1 form-label' htmlFor="fiber_task">{translate('reports.fiber_task')}</label>
																<AsyncPaginate
																	className="custom-react-select form-control input-with-label"
																	inputId="fiber_task"
																	value={searchStats.fiber_task  ? searchStats.fiber_task : null}
																	loadOptions={this.getDropDownList}
																	onChange={this.handleChange("fiber_task")}
																	additional={{ page: 1, type: "fiberTask"}}
																	isClearable={true}
																	placeholder={translate('reports.search_by_fiber_task')}
																/>
															</div>
														</div>
													:null}
												</div>
											</div>

											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="users-list bg-transparent p-0">
					<div className="row">
						<div className="col-lg-12 text-end">
							<div className="form-group export-btns">
								<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={()=>{this.exportTableContent(EXPORT_PDF)}}>{translate("reports.export_as_pdf")}</Link>
								<Link to="#" className="btn btn-fill" onClick={()=>{this.exportTableContent(EXPORT_CSV)}}>{translate("reports.export_as_csv")}</Link>
							</div>
						</div>
					</div>
					<div className="table-responsive theme-table">
						<Table bordered responsive>
							<thead>
								<tr>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "job_number", (sortStats && sortStats.key === "job_number" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.job_number')}
										<span className="m-l-5">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "job_number") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "job_number" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "work_order_number", (sortStats && sortStats.key === "work_order_number" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.work_order')}

										<span className="m-l-5">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "work_order_number") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "work_order_number" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='10%'>
										{translate('job_report.project_manager')}
									</th>
									<th width='10%'>
										{translate('job_report.foreman')}
									</th>
									<th width='10%'>
										{translate('job_report.employees')}
									</th>
									<th width='10%'>
										{translate('job_report.equipments')}
									</th>
									<th width='10%'>
										{translate('job_report.fiber_task')}
									</th>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "daily_project_value", (sortStats && sortStats.key === "daily_project_value" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.daily_project_value')}

										<span className="m-l-5">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "daily_project_value") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "daily_project_value" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "time_duration", (sortStats && sortStats.key === "time_duration" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.project_duration')}

										<span className="m-l-5">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "time_duration") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "time_duration" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "total_cost", (sortStats && sortStats.key === "total_cost" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.estimated_cost')}

										<span className="m-l-5">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "total_cost") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "total_cost" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{isLoading ?
									<tr>
										<td colSpan="10" className='text-center'>
											<img src={loaderGif} alt="loading-icon" />
										</td>
									</tr>
								:
								jobList && jobList && jobList.length  ?
									jobList.map((row, rowIndex)=>
										<tr key={"row"+rowIndex}>
											<td>{row.job_number ? row.job_number :"-"}</td>
											<td>{row.work_order_number ? row.work_order_number :"-"}</td>
											<td>{row.project_manager_id ? row.project_manager_id :"-"}</td>
											<td>{row.foreman_id ? row.foreman_id :"-"}</td>
											<td>{row.employee_ids ? row.employee_ids :"-"}</td>
											<td>{row.equipment_ids ? row.equipment_ids :"-"}</td>
											<td>{row.fiber_task ? row.fiber_task :"-"}</td>
											<td>{row.daily_project_value ? CURRENCY_SYMBOL+" "+customFixedValue(row.daily_project_value) :"-"}</td>
											<td>{row.time_duration ? row.time_duration :"-"}</td>
											<td>{row.total_cost ? CURRENCY_SYMBOL+" "+customFixedValue(row.total_cost) :0}</td>
										</tr>
									)
								:
									<tr>
										<td colSpan="10" className='text-center'>
											{translate("datatable.no_records_found")}
										</td>
									</tr>
								}
							</tbody>
							{jobList && jobList && jobList.length  ?
								<tfoot>
									<tr>
										<th colSpan="7" className='text-end'> {translate("reports.total")}</th>
										<th>
											{this.getFooterTotalValue('daily_project_value')}
										</th>
										<th>
											{this.getFooterTotalValue('time_duration')}
										</th>
										<th>
											{this.getFooterTotalValue('total_cost')}
										</th>
									</tr>
								</tfoot>
							:null}
						</Table>
					</div>
				</div>
			</div>
		</>
	}
}
export default JobLookup;
