import React, { Component } from "react";
import JsonView from '@uiw/react-json-view';
import { darkTheme } from '@uiw/react-json-view/dark';

import { callBackendAPI} from './../../helpers/common';
import {STATUS_SUCCESS, CALENDAR_MODIFIED_DATE_TIME_FORMAT}  from './../../config/global_constants';
import { translate, formatDate  } from './../../helpers/intl_helpers';

import dateIcon from '../../assets/images/calendar_icon.svg';
import userIcon from '../../assets/images/user_icon.svg';
import addIcon from '../../assets/images/add_plus_new_icon.svg';

export default class JobLogs extends Component {
	constructor(props){
		super(props);

        this.state = {
			isLoading	: 	true,
			jobLogList	: 	[],
        };

		this.fetchEventLogsList = this.fetchEventLogsList.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to call initial function.
	 */
	componentDidMount() {
        if(this.props.eventId) {
            this.fetchEventLogsList(this.props.eventId);
        }else if(this.props.logId) {
            this.fetchEventLogsList(this.props.logId,true);
		}
    }

	/**
	 * This function invoked immediately after updating occurs. This method is not called for the initial render.
	 * Its call when another view job logs modal open
	 */
	componentDidUpdate(prevProps) {
        if (this.props.eventId !== prevProps.eventId) {
            this.fetchEventLogsList(this.props.eventId);
        }else if (this.props.logId !== prevProps.logId) {
            this.fetchEventLogsList(this.props.logId,true);
        }
    }

	/** Get Job Logs Details */
	fetchEventLogsList(eventId,isLog){
		window.showLoader();

		/** Update loading stats */
		this.setState({ isLoading : true},()=>{
			let apiParams = { model: 'jobs', method: 'system-logs', complete_list: true};
			if(!isLog) apiParams.target_id = eventId;
			else apiParams.log_id = eventId;

			/** Call job logs list API  */
			callBackendAPI([apiParams]).then(response =>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					/** Update stats */
					this.setState({
						isLoading : false,
						jobLogList: response.data[0].result
					},()=>{
						window.openModal('eventLogsModal');
						window.hideLoader();
					});
				}else{
					this.closeJobLogsModal();
				}
			}).catch(err => console.log(err));
		});
	}//fetchEventLogsList

	/**
     * Closes the job logs modal by updating the state and
     * removing modal-related classes from the body.
    */
	closeJobLogsModal = ()=>{
		if(this.props.handleCloseJobLogModal) this.props.handleCloseJobLogModal();
    }// end closeJobLogsModal()

	render() {
		const {isLoading,jobLogList} = this.state;

		return <>
			{!isLoading ?
				<div className="modal fade view-modal job-details-modal" id="eventLogsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="eventLogsModal" aria-hidden="true">
					<div className="modal-dialog modal-xl">
						<div className="modal-content">
							<button type="button" className="btn-close" onClick={()=> {this.closeJobLogsModal()} }>X Close</button>
							<div className="modal-body">
								<h3>
									{translate('calendar.history')}
								</h3>
								<div className="accordion" id="jobLogAccordion">
									{jobLogList && jobLogList.length > 0 ?
										jobLogList.map((record, index)=>
											<div className="accordion-item foremen-history-box" key={"logs-"+record._id}>
												<h2 className="accordion-header">
													<div className={'accordion-button '+(index > 0 ? 'collapsed' :'')} data-bs-toggle="collapse" data-bs-target={"#jobLogsCollapse-"+record._id} aria-expanded="true" aria-controls={"jobLogsCollapse-"+record._id}>
														<span className="me-3">
															<img src={userIcon} alt="user" width="16" height="16" className="me-2"/>{record.action_taken_by && record.action_taken_by.full_name ? record.action_taken_by.full_name :""}
														</span>
														<span className="me-3">
															<img src={addIcon} alt="user" width="16" height="16" className="me-2"/>{record.activity_type ? record.activity_type :""}
														</span>
														<span>
															<img src={dateIcon} alt="date" width="16" height="16" className="me-2"/>{formatDate(record.created,CALENDAR_MODIFIED_DATE_TIME_FORMAT)}
														</span>
													</div>
												</h2>
												<div id={"jobLogsCollapse-"+record._id} className={'accordion-collapse collapse '+(index < 1 ? 'show' :'')} data-bs-parent="#jobLogAccordion-0">
													<div className="accordion-body">
														<div className="list-group list-group-flush">
															<div className="row">
																<div className="col-lg-6">
																	<JsonView value={record.old_values ? record.old_values :{}} style={darkTheme} displayDataTypes={false} />
																	</div>
																<div className="col-lg-6">
																	<JsonView value={record.new_values ? record.new_values :{}} style={darkTheme} displayDataTypes={false} />
																	</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										)
									:null}
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-fill" onClick={()=> {this.closeJobLogsModal()} } >Close</button>
							</div>
						</div>
					</div>
				</div>
			:null}
		</>
	}
}
