import React, { Component } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class OverlayTriggerElement extends Component {
    renderTooltip = (props) => {
        const { title } = this.props; // Get the title from props
        return (
            <Tooltip id={this.props.id || 'custom-tooltip-'+String(new Date().getTime())} {...props} >
                <div className="nl2br" dangerouslySetInnerHTML={{__html: title ? title : "" }} />
            </Tooltip>
        );
    };

    render() {
        const { placement = 'top', delay = { show: 250, hide: 400 }, children } = this.props;
        return (
            <OverlayTrigger placement={placement} delay={delay} overlay={this.renderTooltip} >
                {children}
            </OverlayTrigger>
        );
    }
}
export default OverlayTriggerElement;