import React, { Component } from "react";
import { callBackendAPI, getRandomString} from './../../helpers/common';
import { translate, formatDate } from './../../helpers/intl_helpers';
import { CALENDAR_MODIFIED_DATE_TIME_FORMAT}  from './../../config/global_constants';

import NormalDataTable from './../NormalDataTable';
import JobLogsModal from './../calendar/JobLogsModal';

export default class SystemLogs extends Component{
	constructor(props) {
        super(props);

		this.state = {
			tableKey 	: 	getRandomString(),
			logId		: 	""
		}

		this.customAction 	=	this.customAction.bind(this);
		this.customRender 	= 	this.customRender.bind(this);
		this.hideModal		= 	this.hideModal.bind(this);
    }

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type, searchStats }){
        try{
			let apiReq = {model: 'jobs', method: 'collections', name: inputValue ? inputValue :''};
			if(type === "action_taken_by") apiReq = {model: 'calendar', method: 'employeeList', is_crew_member: true, emp_name: inputValue ? inputValue :''};
			if(type === "crewMember")  	apiReq	=	{ model: 'calendar', method: 'employeeList', emp_name: inputValue ? inputValue : '', is_crew_member: true};
			if(type === "asset") 	 apiReq	= { model: 'calendar', method: 'assetsList', asset_name: inputValue ? inputValue : ''};

			if(type === "jobNumber") apiReq = {model: 'calendar', method: 'cannonJobList', job_number: inputValue ? inputValue : ''};
			if(type === "workOrder"){
				let jobArr = [];
				if(searchStats && searchStats.jobNumber && searchStats.jobNumber.length) jobArr = searchStats.jobNumber.map(element => element.value);

				apiReq = {model: 'calendar', method: 'workOrderList', job_id: jobArr, work_order_number: inputValue ? inputValue : '', is_job_report: true };
			}

			/** Add page number */
            apiReq.current_page = page;

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/** Manage table custom render */
	customRender = (actionKey, rowData) => {
		if(!actionKey || !rowData || !rowData._id)  return "";

		let element = "";
		if(actionKey === "action_taken_by"){
			element	= rowData.action_taken_by && rowData.action_taken_by.full_name ? rowData.action_taken_by.full_name :"";
		}
		if(actionKey === "created"){
			element	= rowData.created ?	formatDate(rowData.created , CALENDAR_MODIFIED_DATE_TIME_FORMAT)  :"";
		}

		return element;
	};// end customRender()

	customAction = (actionKey, rowData) => {
		if(actionKey === "view") this.setState({logId: rowData._id,});
	}

	/** Manage model hide event */
	hideModal = () => {
		window.closeModal('eventLogsModal');
		this.setState({logId: ""});
	}

	render() {
		const {tableKey,logId} = this.state;

		let pageStats = {
			tableId 	:	'SystemLogs',
			pageType 	:	"SystemLogs",
			title 		:	translate("system_logs.heading_title"),
			columns :	[
				{name: translate("system_logs.action_taken_by"), selector: "action_taken_by", customRender: this.customRender, width: "20%"},
				{name: translate("system_logs.collection"), selector: "collection_title", width: "20%"},
				{name: translate("system_logs.activity_type"), selector: "activity_type", width: "20%"},
				{name: translate("system_logs.created"), selector: "created", width: "20%", customRender: this.customRender, sortable: true, defaultSort: true, direction: "desc"},
				{name: translate("system.action"), selector: "", width: "5%", custom_action: this.customAction, actions: [{key: "view"}]}
			],
			search :	[
				{name: translate("system_logs.created"), selector: "created", input_type: "date_range", inputClass:"input-with-label"},
				{name: translate("system_logs.action_taken_by"), selector: "action_taken_by", input_type: "asyncPaginate" },
				{name: translate("system_logs.collection"), selector: "collection", input_type: "asyncPaginate" },
				{name: translate("reports.job_number"), selector: "jobNumber", input_type: "asyncPaginate", isMultiple:true, child_selector:"workOrder" },
				{name: translate("reports.work_order_number"), selector: "workOrder", input_type: "asyncPaginate", isMultiple: true, parent_selector:"jobNumber" },
				{name: translate("reports.employee"), selector: "crewMember", input_type: "asyncPaginate",isMultiple:true},
				{name: translate("reports.equipment"), selector: "asset", input_type: "asyncPaginate", isMultiple:true},
			]
		}
		return <>
			<NormalDataTable key={tableKey} pageStats={pageStats} apiParm={{model: 'jobs', method: 'system-logs', onlyList: true}} apiExport={{model: 'jobs', method: 'systemLogExports',complete_list : true, onlyList: true}}  getDropDownList={this.getDropDownList}/>

			{/* Show log modal */}
			{logId &&
				<JobLogsModal logId={logId} handleCloseJobLogModal={this.hideModal}  />
			}
		</>
	}
}