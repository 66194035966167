import React, { Component ,Suspense} from "react";
import { translate, formatDate  } from './../../helpers/intl_helpers';
import NormalDataTable from './../NormalDataTable';
import {DATERANGE_DATE_TIME_FORMAT}  from './../../config/global_constants';
import { callBackendAPI,customConfirmBox, reFormatSuccess, getRandomString} from './../../helpers/common';

import AddEditForm from './AddEditForm';

class ScheduleList extends Component {
	constructor(props) {
        super(props)

        this.state = {
			showModel		: 	false,
			searchStats 	: 	{},
			tableKey 		:	getRandomString(),
        };

		this.toggleModal 			= 	this.toggleModal.bind(this);
		this.refreshDataTable   	= 	this.refreshDataTable.bind(this);
		this.handleDeleteWorkOrder 	=	this.handleDeleteWorkOrder.bind(this);
		this.anchorRef 				=	React.createRef();
    }

	/** Refresh listing data-table */
	refreshDataTable() {
		this.setState({
			tableKey : getRandomString(),
		});
    }

	/** Function to handle delete button click */
	handleDeleteWorkOrder = (scheduleId) => {
		const confirmation = customConfirmBox(translate('schedule.you_want_to_delete_the_schedule'));
		confirmation.then((confirmed) => {
			if(confirmed) {
				callBackendAPI([{ model: 'schedule', method: 'deleteOne', schedule_id: scheduleId }]).then((response) => {
					if(response.success) {
						reFormatSuccess(response.data[0].message);
						this.setState({tableKey: getRandomString()});
					}
				}).catch(err => console.log(err));
			}
		});
	}

	/** For manage action button click event */
	customAction = (actionKey, rowData) => {
		if(actionKey === "edit" && rowData._id){
			this.setState({
				showModel 	:	true,
				scheduleId	: 	rowData._id
			});
		}else if(actionKey === "add"){
			this.setState({
				showModel:	true
			});
		}else if(actionKey === "delete" && rowData._id) {
			this.handleDeleteWorkOrder(rowData._id)
		}
	}

	/** Manage table custom render */
	customRender = (actionKey, rowData) => {
		if(!actionKey || !rowData || !rowData._id)  return "";

		let element = "";
		if(actionKey === "date"){
			element	= rowData.date ? formatDate(rowData.date , DATERANGE_DATE_TIME_FORMAT)  :"";
		}
		return element;
	};// end customRender()

	/** Manage add / update modal box event */
	toggleModal(close) {
		this.setState({
			showModel	: close,
			scheduleId	: null
		});
	}


	render() {
		const {showModel , scheduleId, tableKey } = this.state;

		let pageStats = {
			tableId 	:	'scheduleList',
			title 		:	translate("schedule.heading_title"),
			headerLinks	: 	[
				{name: translate("schedule.add_schedule"), selector: "add", clickEvent: this.customAction }
			],
			columns :	[
				{name: translate("schedule.date"), selector: "date",sortable: true, width: "20%",is_date: true, customRender: this.customRender},
				{name: translate("schedule.subject"), selector: "title", width: "20%"},
				{name: translate("schedule.description"), selector: "description", width: "50%", rowClass: 'nl2br'},
				{name: translate("system.action"), selector: "", width: "10%", custom_action: this.customAction, actions: [{key: "edit"}, {key: "delete"}]},

			],
			search :	[
				{name: translate("schedule.subject"), selector: "title", 	input_type: "text"},
			]
		}

		return <>

			<NormalDataTable ref={(ref) => this.dataTableComponent = ref} pageStats={pageStats} key={tableKey}  apiParm={{model: 'schedule', method: 'scheduleList'}} />

			{showModel ?
				<Suspense fallback={<div></div>}> <AddEditForm isFormVisible={showModel} toggleModal={this.toggleModal} scheduleId={scheduleId} refreshDataTable={this.refreshDataTable}/> </Suspense>
			:null}
		</>
	}
}
export default ScheduleList;
