import "react-datepicker/dist/react-datepicker.css";

import React, { Component, forwardRef } from 'react'
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import { translate, formatDate, formatTime } from '../../../helpers/intl_helpers';
import { CALENDAR_DATE_TIME_FORMAT, DATE_RANGE_PICKER_FORMAT, RADIO_YES,RADIO_NO, STATUS_SUCCESS, DEPARTMENT_DROPDOWN, TIME_PICKER_FORMAT } from '../../../config/global_constants';
import { handleFormValidation, handleFormInputs,  callBackendAPI, customConfirmBox, isWeekendDay, reFormatSuccess, getRandomString, manageShiftTime, reFormatErrors  } from '../../../helpers/common';

import DeleteIcon 	 from '../../../assets/images/delete-icon.svg';

const CustomTimeInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
        ref={ref} // Now the ref is forwarded
        value={value}
        onClick={onClick}
        readOnly
        placeholder="Select time range"
        className="form-control"
        style={{ cursor: 'pointer' }}
    />
));

export default class AddPtoYardJob extends Component {
	constructor(props) {
        super(props);

        let dateObj 	=	(this.props.dateObj) ? this.props.dateObj :{};
		let dragItem	= 	(dateObj && dateObj.drag_item && (dateObj.drag_item !== undefined)) ? dateObj.drag_item :"";
		let employees	=	[];
		let unpaidEmpList=	[];
		let equipment	=	[];
		let startDate 	=	dateObj.start_date 	?	dateObj.start_date 	:new Date();
		let endDate 	=	dateObj.end_date 	? 	dateObj.end_date 	:new Date();
		let slotType	=	(dateObj && dateObj.slot_type) ? dateObj.slot_type : "";
		let empType		=	(dateObj && dateObj.emp_type) ? dateObj.emp_type : "";
		let roleType	=	(dateObj && dateObj.role_type) ? dateObj.role_type : "";
		let isMobile	=	(dateObj && dateObj.isMobileScreen) ? dateObj.isMobileScreen : false;
		let isYard 		=	slotType === "yard" ? true :false;
		let isPTO 		=	slotType === "pto" ? true :false;

		if(isYard && dragItem && dragItem.state && dragItem.state && !dragItem.state.type) employees = [{_id:dragItem.id, value:dragItem.id,label: dragItem.name}];
		if(dragItem && dragItem.state && dragItem.state && (dragItem.state.type === "asset")) equipment = [{_id:dragItem.id,value:dragItem.id,label: dragItem.name}]

		let jobDetails 	=	(this.props.jobDetails) ? this.props.jobDetails :{};

		let isEdit		=	jobDetails.isEdit ? jobDetails.isEdit : false;
		let isCopy		=	jobDetails.isCopy ? jobDetails.isCopy : false;
		let notes		=	jobDetails.notes  ? jobDetails.notes  : null;
		let subject		=	jobDetails.subject  ? jobDetails.subject  : null;
		let jobId		=	isEdit && jobDetails._id ? jobDetails._id :"";
		let startTime 	=	manageShiftTime();
		let endTime 	=	manageShiftTime(true);

		if(isEdit && jobDetails.unpaid_employee_ids && jobDetails.unpaid_employee_ids.length) empType = "unpaid";
		if(isEdit && jobDetails.paid_employee_list && jobDetails.paid_employee_list.length) empType = "paid";

		if(isPTO && dragItem && dragItem.id && dragItem.name){
			if(empType === "paid") employees = [{_id:dragItem.id, value:dragItem.id,label: dragItem.name}];
			if(empType === "unpaid") unpaidEmpList = [{_id:dragItem.id, value:dragItem.id,label: dragItem.name}];
		}

		if(isEdit && jobDetails.date){
			startDate = new Date(jobDetails.date);
			endDate = new Date(jobDetails.date);
		}

		if(isCopy && jobDetails.selected_date && formatDate(jobDetails.selected_date, CALENDAR_DATE_TIME_FORMAT) >= formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
			startDate	=	{value: jobDetails.selected_date};
			endDate		=	{value: jobDetails.selected_date};
		}

		// if(jobDetails.assets && jobDetails.assets.length){
		// 	jobDetails.assets.forEach(element => {
		// 		equipment.push({_id: element.id, value: element.id, label: element.label});
		// 	});
		// }

		if(jobDetails.assets && jobDetails.assets.length){
			equipment = jobDetails.assets;
		}

		if(isYard){
			if(jobDetails.crewMembers && jobDetails.crewMembers.length){
				jobDetails.crewMembers.forEach(element => {
					employees.push({_id: element.id, value: element.id, label: element.full_name});
				});
			}

			if(jobDetails.start_time_24_hour) 	startTime 	=	manageShiftTime(null, jobDetails.start_time_24_hour);
			if(jobDetails.end_time_24_hour) 	endTime 	= 	manageShiftTime(true, jobDetails.end_time_24_hour);
		}else{
			if(jobDetails.paid_employee_list && jobDetails.paid_employee_list.length && empType === "paid"){
				jobDetails.paid_employee_list.forEach(element => {
					employees.push({_id: element.id, value: element.id, label: element.full_name});
				});
			}

			if(jobDetails.unpaid_employee_list && jobDetails.unpaid_employee_list.length && empType === "unpaid"){
				jobDetails.unpaid_employee_list.forEach(element => {
					unpaidEmpList.push({_id: element.id, value: element.id, label: element.full_name});
				});
			}
		}

		let jobGroupId		=	"";
		let jobGroupColor	=	"#6f0e0e";
		if(isYard && isEdit){
			if(jobDetails && jobDetails.job_group_id) 	 jobGroupId		= 	jobDetails.job_group_id;
			if(jobDetails && jobDetails.job_group_color) jobGroupColor	=	jobDetails.job_group_color;
		}

        this.state = {
			fields: {
				start_time		: 	{value: startTime},
				end_time		: 	{value: endTime},
                job_group_id	: 	{value: jobGroupId},
                job_group_color	: 	{value: jobGroupColor},
                start_date		: 	{value: startDate},
                end_date		: 	{value: endDate},
                slotType		: 	slotType,
				employees		: 	employees.length  		? 	employees 		:null,
				unpaid_employees: 	unpaidEmpList.length	?	unpaidEmpList 	:null,
				equipment		: 	equipment.length  		?	equipment  		:null,
				notes			: 	{value: notes},
				subject			: 	{value: subject},
				date_range 		: 	isCopy ? [null, null] : [new Date(startDate), new Date(endDate)],
				is_hide_weekend_date: {value: jobDetails.is_hide_weekend_date ? String(jobDetails.is_hide_weekend_date) :  String(RADIO_NO)},
				location_id		: 	jobDetails.location_list ? jobDetails.location_list : [],
				department_id	: 	jobDetails.department_list ? jobDetails.department_list : [],
            },
			defaultDateRange: 	[subDays(new Date(), 6), new Date()],
			isYard			: 	isYard,
			isPTO			: 	isPTO,
			errors      	:	{},
            loading     	: 	true,
            submitted		:	false,
			jobId  			: 	jobId,
			isEdit  		: 	isEdit,
			isCopy  		: 	isCopy,
			paidUnpaidVal	:  	empType,
			isMobileScreen	: 	isMobile,
			random_key 		: 	getRandomString(),
			locationList    : 	[],
			employeesList 	: 	[],
            equipmentList 	: 	[],
			roleType		: 	roleType
        };

        this.handleFormInputs 	= 	this.handleFormInputs.bind(this);
		this.handleFormSubmit 	=  	this.handleFormSubmit.bind(this);
		this.handleChange 		= 	this.handleChange.bind(this);
		this.closeModal			=  	this.closeModal.bind(this);
    }

	/**
	* For data form api
	*/
	componentDidMount() {
        const { fields, isEdit, isYard} = this.state;
		this.getEmpList();
		this.getEquipList();

		if(isYard){
			window.showLoader();

			let apiParams = [{model: 'home', method: 'masterList', type: ["location"] }];
			// if(!isEdit) apiParams.push({model: 'jobs', method: 'generate-group-id'});

			/** Call API */
			callBackendAPI(apiParams).then(response => {
				if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
					let tmpstamp	=	{locationList: response.data[0].result.location};
					// if(!isEdit) tmpstamp.fields = {...fields, ...{job_group_id: {value: response.data[1].group_id}}};
					this.setState(tmpstamp,()=>{
						window.hideLoader();
					});
				}
			}).catch(err => console.log(err));
		}
	}

	/**
	 * Handle dropdown value state
	 */
	handleChange = (fieldName) => (fieldVal) => {
		if(fieldName === "start_time") 	fieldVal = {value: fieldVal};
		if(fieldName === "end_time") 	fieldVal = {value: fieldVal};
        let updatedFields = {...this.state.fields, [fieldName]: fieldVal};

		if(fieldName === "start_time" || fieldName === "end_time" || fieldName === "date_range"){
            updatedFields['random_key'] = Math.random();
			this.getEmpList();
			this.getEquipList();
        }

		if (fieldName === 'location_id') updatedFields.department_id = null;
		if (fieldName === "date_range") {
            let startDate = fieldVal[0];
            if (startDate) updatedFields['is_hide_weekend_date'] = { value: isWeekendDay(startDate) ? String(RADIO_YES) : String(RADIO_NO) };
        }
        this.setState({fields: updatedFields});
    }; // end handleChange()

	/**
	* For get equipment listing
	*/
    getEquipList = (onlyAssign = false) =>{
        const { isPTO, start_date, end_date, start_time, end_time, date_range, equipment, employees } = this.state.fields;

		let startDate	= 	(date_range && date_range[0])	?	formatDate(date_range[0],CALENDAR_DATE_TIME_FORMAT)	:"";
		let endDate		=	(date_range && date_range[1])	?	formatDate(date_range[1],CALENDAR_DATE_TIME_FORMAT)	:"";

        /** Set API request params */
        let apiReq = { model: 'calendar', method: 'assetsList', add_job: true, complete_list:true, start_date: startDate ? startDate : ((start_date && start_date.value) ? formatDate(start_date.value,CALENDAR_DATE_TIME_FORMAT): ""), end_date: endDate ? endDate :((end_date && end_date.value) ? formatDate(end_date.value,CALENDAR_DATE_TIME_FORMAT): ""), start_time: (start_time && start_time.value) ? formatTime(start_time.value,"HH:mm") : "",end_time: (end_time && end_time.value) ? formatTime(end_time.value,"HH:mm") : "" };

        if(onlyAssign){
            if(!employees || !employees.length) return false;

            apiReq.assignee_employees = employees;
        }

        callBackendAPI([apiReq]).then(response => {
           if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
                let result = response.data[0].result;
                let transformedOptions  =  [];
                let selectedOptions     =  !result.length && equipment && equipment.length ? equipment :[];
                result.forEach((equip) => {
                    // Apply strikethrough if `isUsed` is true, else leave label as it is
                    let tmpObj = {...equip, label: (equip.isUsed === true)  ? <span className="strikethrough">{equip.label}</span> : equip.label};

                    let isMatched = false;
                    if(equipment && equipment.length){
                        equipment.forEach(data=>{
                            if(data.value === equip.value) isMatched = true
                        });
                    }

                    if(isMatched || onlyAssign) selectedOptions.push(tmpObj);

                    transformedOptions.push(tmpObj);
                });

                if(onlyAssign && equipment && equipment.length && selectedOptions.length){
                    equipment.forEach(data=>{
                        let isMatch = false;
                        selectedOptions.forEach(selData=>{
                            if(selData.value === data.value) isMatch = true;
                        });

                        if(!isMatch) selectedOptions.push(data);
                    });
                }
				console.log("equipment",equipment)
				if(equipment && equipment.length){
                    equipment.forEach(data=>{
                        let isMatch = true;
                        if(data.isAssetInactive || data.isAssetDeleted){
                            isMatch = false;
                            selectedOptions.forEach(selData=>{
                                if(selData.value === data.value) isMatch = true;
                            });
                        }
                        if(!isMatch) selectedOptions.push(data);
                    });
                }

                let updateStats = {fields: {...this.state.fields, ...{equipment: selectedOptions.length ? selectedOptions : ""}}};
                if(!onlyAssign) updateStats.equipmentList = transformedOptions;
                this.setState(updateStats);
            }
       }).catch(err => console.log(err));
    }// end getEquipList()

    /**
	* For get emp listing
	*/
    getEmpList = () =>{
        const { isPTO, start_date, end_date, start_time, end_time, date_range, employees } = this.state.fields;
		const { roleType } = this.state;

        let startDate   = (date_range && date_range[0]) ?	formatDate(date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
        let endDate     = (date_range && date_range[1]) ?	formatDate(date_range[1],CALENDAR_DATE_TIME_FORMAT)	:"";

		/** Set API request params */
        let apiReq = { model: 'calendar', method: 'employeeList', is_crew_member: true, add_job: true, complete_list:true, start_date: startDate ? startDate : ((start_date && start_date.value) ? formatDate(start_date.value,CALENDAR_DATE_TIME_FORMAT): ""), end_date: endDate ? endDate :((end_date && end_date.value) ? formatDate(end_date.value,CALENDAR_DATE_TIME_FORMAT): ""), start_time: (start_time && start_time.value) ? formatTime(start_time.value,"HH:mm") : "",end_time: (end_time && end_time.value) ? formatTime(end_time.value,"HH:mm") : "", role_type: roleType || ""};

        callBackendAPI([apiReq]).then(response => {
           if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
                let result = response.data[0].result;
                let transformedOptions  =  [];
                let selectedOptions     =  [];
                result.map(emp => {
                    // Apply strikethrough if `isUsed` is true, else leave label as it is
                    let tmpObj = {...emp, label: (emp.isUsed === true)  ? <span className="strikethrough">{emp.label}</span> : emp.label};

                    if(employees && employees.length){
                        employees.map(data=>{
                            if(data.value === emp.value){
                                selectedOptions.push(tmpObj);
                            }
                        });
                    }
                    transformedOptions.push(tmpObj);
                });

                this.setState({
                    employeesList : transformedOptions,
                    fields        : {...this.state.fields, ...{employees: selectedOptions.length ? selectedOptions : ""}},
                });
            }
       }).catch(err => console.log(err));
   }// end getEmpList()

   /**
	* Handle time on change
	*/
   handleTimeChange = (date) => {
		this.setState({
			fields : {...this.state.fields, shift_time: {value: date}}
		});
	} // end handleTimeChange()

	 /**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleFormSubmit(e,isForceAssign){
		if(e) e.preventDefault();

		/** Api Requested Data **/
		const {isYard, isPTO, roleType, fields: {employees, equipment, notes, slotType, unpaid_employees, subject, date_range, is_hide_weekend_date, job_group_id, job_group_color, location_id, department_id, start_time, end_time}} = this.state;
		let empArr      = [];
		let equipArr    = [];
		let unEmpArr	= [];
		let locationArr	= [];
		let departmentArr= [];
		if(employees && employees.length) empArr = employees.map(empArr => empArr._id);
		if(equipment && equipment.length) equipArr = equipment.map(equipArr => equipArr._id);
		if(unpaid_employees && unpaid_employees.length) unEmpArr = unpaid_employees.map(empArr => empArr._id);
		if (location_id && location_id.length) locationArr = location_id.map(element => element.value);
		if (department_id && department_id.length) departmentArr = department_id.map(element => element.value);

		this.setState({
			submitted: true,
			fields : {
				...this.state.fields,
				...{
					employee_id: {value: empArr.length ? empArr : (unEmpArr.length ? unEmpArr : null)},
					equipment_id: {value: equipArr.length ? equipArr : null},
					unpaid_employee_id: {value: unEmpArr.length ? unEmpArr : null},
					location: {value: locationArr.length ? locationArr : null},
                    department: {value: departmentArr.length ? departmentArr : null},
				}
			}
		},()=>{

			let params = [{
				model				: 	'jobs',
				method				: 	'addYardOrPtoJob',
				job_id		    	: 	this.state.jobId ?	this.state.jobId :"",
				start_time			:	(start_time && start_time.value)    ?   formatTime(start_time.value,"HH:mm")    :"",
				end_time			:	(end_time && end_time.value)	    ?   formatTime(end_time.value,"HH:mm")	    :"",
				employee_id			: 	(empArr && empArr.length)        	?	empArr	        	:null,
				equipment_id		: 	(equipArr && equipArr.length)       ?	equipArr	        :null,
				unpaid_employee_id	: 	(unEmpArr && unEmpArr.length)       ?	unEmpArr	        :null,
				start_date			: 	(date_range && date_range[0])		?	formatDate(date_range[0],CALENDAR_DATE_TIME_FORMAT)		:"",
				end_date			:	(date_range && date_range[1])		?	formatDate(date_range[1],CALENDAR_DATE_TIME_FORMAT)		:"",
				notes				:	(notes && notes.value)				?	notes.value			:"",
				subject				:	(subject && subject.value)			?	subject.value		:"",
				type				:	slotType,
				is_hide_weekend_date:   (is_hide_weekend_date && is_hide_weekend_date.value) ? 	is_hide_weekend_date.value 			:"",
				emp_type			:   this.state.paidUnpaidVal,
				location			:	locationArr,
				department			:	departmentArr,
				role_type			:	roleType || "",
			}];

			if(isYard){
				params[0]['location'] 			= 	locationArr;
				params[0]['department'] 		= 	departmentArr;
				params[0]['job_group_id'] 		= 	(job_group_id && job_group_id.value)        ?   job_group_id.value      :"";
				params[0]['job_group_color'] 	=	(job_group_color && job_group_color.value)	?   job_group_color.value	:"";
			}

			if(isForceAssign) params[0]['force_assign'] = true;

			/** Validation Rules **/
			let validations = {employee_id : 	{"label": translate('calendar.employees'), "rules" :["select"] }}

			if(isYard){
				validations["subject"]  		=  {"label": translate('calendar.subject'), "rules" :["required"] };
				validations["location"]			=	{label: translate('employee.location'), "rules": ["select"] } ;
				validations["department"]		=	{label: translate('employee.department'), "rules": ["select"] };
				// validations["job_group_id"]  	=  {"label": translate('calendar.job_group_id'), "rules" :["required"] };
				// validations["job_group_color"]  =  {"label": translate('calendar.job_group_color'), "rules" :["select"] };

				if (!empArr.length && !equipArr.length) {
					validations["employee_id"] = { "label": translate('calendar.employees'), "rules": ["select"] };
					validations["equipment_id"] = { "label": translate('calendar.equipment'), "rules": ["select"] };
				} else {
					// Remove the validations if either array has data
					delete validations["employee_id"];
					delete validations["equipment_id"];
				}
			}else if(isPTO){
				if(!empArr.length || !unEmpArr.length){
					validations["employee_id"]  =  {"label": translate('calendar.employees'), "rules" :["select"] };
				}
			}

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params, true,true,true).then(response=>{
				if(!response.success){
					if(response.errors && response.data){
						let tmpErrors =  reFormatErrors(response.data.message);
						let isForceAssign = (tmpErrors &&  tmpErrors != null && tmpErrors.force_resource_id ? tmpErrors.force_resource_id : "");

						this.setState({ errors : response.errors, submitted: false},()=>{
							if(isForceAssign){
								window.closeModal('addPtoYardJob');
								let confirmation = customConfirmBox(isForceAssign,"",false,true);
								confirmation.then((confirmed) => {
									if (confirmed) {
										this.handleFormSubmit(null,true)
									}else{
										window.openModal('addPtoYardJob');
									}
								}).catch(err => console.error("Confirmation dialog error:", err));
							}
						});
					}else if(response.errors){
						this.setState({ errors : response.errors, submitted: false})
					}
				}else{
					/** Call parent function */
					if(this.props.updateEventCalendar) this.props.updateEventCalendar(null,"calendar");
					/** Hide loader */
					this.closeModal();
				}
			}).catch(err => console.log(err));
		})
	}

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('addPtoYardJob');
        this.setState({ submitted: false, errors: {}, fields: {} });
		this.props.handleCloseTaskModal();
	}// end closeModal()

	/**Handle onclick function for remove job */
    handleRemoveJob = (e) =>{
        e.preventDefault();
        const { jobId } = this.state;

        let confirmation = customConfirmBox(translate("calendar.you_want_to_remove_this_job"));
        confirmation.then((confirmed) => {
            if (confirmed) {
                /** Set API request params */
                let apiReq = [ { model: 'jobs', method: 'deleteJob', job_id: jobId},]
                callBackendAPI(apiReq).then(response => {
                    if(response.success && response.data[0].status === STATUS_SUCCESS) {
                        reFormatSuccess(response.data[0].message)
                        this.closeModal()
                        this.props.updateEventCalendar(null,"calendar");
                    }
                }).catch(err => console.log(err));
            }
        });
    }// end handleRemoveItem()

	handleMenuOpen = () => {
        this.getEmpList();
    };
    handleEquipMenuOpen = () => {
        this.getEquipList();
    };

	render() {
		const {submitted, fields, errors, isEdit, isCopy, paidUnpaidVal, isMobileScreen, isYard, random_key, locationList, employeesList, equipmentList} = this.state;

		return <div className="modal fade crew-modal" id="addPtoYardJob" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="assSubContractorJobLabel" aria-hidden="true">
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<button type="button" className="btn-close" onClick={()=>{this.closeModal()}}>X Close</button>
					{(isEdit && isYard) &&
						<button type="button" className="job-delete-btn" onClick={(e)=> { this.handleRemoveJob(e)}}><img src={DeleteIcon} alt="img" /></button>
					}
					<div className="modal-body">
					  	<h3>
							{isYard ?
								<>
									{isCopy ? translate('calendar.clone_yard') :(isEdit ? translate('calendar.update_yard') :translate('calendar.add_yard'))}
								</>
							:
								<>
									{isCopy ? translate('calendar.clone_pto') :(isEdit ? translate('calendar.update_pto') :translate('calendar.add_pto'))}
								</>
							}
						</h3>
						<form onSubmit={(event) => this.handleFormSubmit(event)} noValidate>
							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
								{(isYard) &&
									<div className="row">
										<div className="col-lg-6">
											<div className='form-group'>
												<label htmlFor="location" className="form-label">{translate('calendar.location')} <span className="error text-danger">*</span></label>
												<Select
													className="custom-react-select"
													placeholder={<label htmlFor="location">{translate("employee.location")}<span className="error text-danger">*</span></label>}
													options={locationList}
													value={fields.location_id ? fields.location_id : null}
													closeMenuOnSelect={true}
													isMulti
													onChange={this.handleChange("location_id")}
												/>
												<span className="error text-danger">{this.state.errors["location"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className='form-group'>
												<label htmlFor="department" className="form-label">{translate('calendar.department')} <span className="error text-danger">*</span></label>
												<Select
													className="custom-react-select"
													value={fields.department_id ? fields.department_id : null}
													options={(fields.location_id) ? DEPARTMENT_DROPDOWN.filter(dept => fields.location_id && fields.location_id.some(loc => loc.department.includes(dept.value))) : []}
													onChange={this.handleChange("department_id")}
													closeMenuOnSelect={true}
													isMulti
													placeholder={<label htmlFor="department">{translate("employee.department")}<span className="error text-danger">*</span></label>}
												/>
												<span className="error text-danger">{this.state.errors["department"]}</span>
											</div>
										</div>
									</div>
								}
								{!isEdit ?
									<div className="row">
										<div className="col-lg-6">
											<div className="form-group" id="modaldialogid">
												<label htmlFor="date" className="form-label">{translate('calendar.select_date')}<span className="error text-danger">*</span></label>
												<DateRangePicker
													value={fields.date_range ? fields.date_range :null}
													format={DATE_RANGE_PICKER_FORMAT}
													size="lg"
													className='form-control input-with-label date-range-picker-common'
													onChange={this.handleChange("date_range")}
													placeholder={translate('reports.search_by_date')}
													cleanable={false}
													ranges={[]}
													editable={false}
													singledatepicker= "true"
													showdropdowns= "true"
													autoupdateinput= "true"
													parente1= "#modaldialogid"
													showOneCalendar={isMobileScreen ? true : false}
												/>
												<span className="error text-danger">{errors["start_date"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className='form-group'>
												<label htmlFor="is_hide_weekend_date" className="form-label">{translate('calendar.is_hide_weekend_date')} </label>
												<div className='form-group checkbox-row order-checkbox-row'>
													<div className="form-check form-check-inline custom-checkbox">
														<input className="form-check-input" type="radio" name="is_hide_weekend_date" id="is_hide_weekend_dateyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_hide_weekend_date")} checked={fields.is_hide_weekend_date && fields.is_hide_weekend_date.value === String(RADIO_YES) ? true :false}/>
														<label className="form-check-label" htmlFor="is_hide_weekend_dateyes">
														{translate('calendar.yes')}
														</label>
													</div>
													<div className="form-check form-check-inline custom-checkbox">
														<input className="form-check-input" type="radio" name="is_hide_weekend_date" id="is_hide_weekend_dateno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_hide_weekend_date")} checked={fields.is_hide_weekend_date && fields.is_hide_weekend_date.value === String(RADIO_NO) ? true :false} />
														<label className="form-check-label" htmlFor="is_hide_weekend_dateno">
														{translate('calendar.no')}
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								:null}

								{isYard &&
								   <>
										<div className="row">
											<div className="col-lg-6">
												<div className="form-group">
													<label htmlFor="start_time" className="form-label">{translate('calendar.start_time')}</label>
													<DatePicker
														selected={fields.start_time && fields.start_time.value ? fields.start_time.value : null}
														onChange={this.handleChange('start_time')}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={1}
														timeCaption={translate("calendar.time")}
														dateFormat={TIME_PICKER_FORMAT}
														timeFormat={TIME_PICKER_FORMAT}
														className="form-control"
														placeholderText={translate('calendar.start_time')}
														customInput={<CustomTimeInput />}
													/>
													<span className="error text-danger">{errors["start_time"]}</span>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<label htmlFor="end_time" className="form-label">{translate('calendar.end_time')}</label>
													<DatePicker
														selected={fields.end_time && fields.end_time.value ? fields.end_time.value : null}
														onChange={this.handleChange('end_time')}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={1}
														timeCaption={translate("calendar.time")}
														dateFormat={TIME_PICKER_FORMAT}
														timeFormat={TIME_PICKER_FORMAT}
														className="form-control"
														placeholderText={translate('calendar.end_time')}
														customInput={<CustomTimeInput />}
													/>
													<span className="error text-danger">{errors["end_time"]}</span>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-12">
												<div className="form-group">
													<label htmlFor="subject" className="form-label">{translate('calendar.subject')} <span className="error text-danger">*</span></label>
													<input type="text" className="form-control" placeholder="Subject" name='subject' id='subject' value={fields["subject"] && fields["subject"]["value"] ? fields["subject"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "subject")} />
													<span className="error text-danger">{errors["subject"]}</span>
												</div>
											</div>
										</div>
								   </>
								}

								{(paidUnpaidVal === "" || paidUnpaidVal === "paid") ?
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												{isYard && (paidUnpaidVal === "") ?
													<label htmlFor="employees" className="form-label">
														{translate('calendar.employees')}
													</label>
												:
													<label htmlFor="employees" className="form-label">
														{translate('calendar.paid_employees')}
													</label>
												}
												<Select
													key={random_key}
													className="custom-react-select"
													value={fields.employees  ? fields.employees : null}
													options={employeesList}
													onChange={this.handleChange("employees")}
													closeMenuOnSelect={true}
													onMenuOpen={this.handleMenuOpen}
													isMulti
													isSearchable={true}
													placeholder={<label htmlFor="employees">{translate('calendar.select_employees')}</label>}
													filterOption={(option, inputValue) => {
														// Extract the label text if it's a JSX element, otherwise, use the string directly
														const labelText = typeof option.label === 'string' ? option.label : option.label.props.children;
														// Perform case-insensitive search
														return labelText.toLowerCase().includes(inputValue.toLowerCase());
													}}
												/>
												<span className="error text-danger">{this.state.errors["employees"]}</span>
												<span className="error text-danger">{this.state.errors["employee_id"]}</span>
											</div>
										</div>
									</div>
								:null}

								{!isYard && (paidUnpaidVal === "unpaid" || paidUnpaidVal === "") ?
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="unpaid_employees" className="form-label">{translate('calendar.unpaid_employees')}</label>
												<Select
													key={random_key}
													className="custom-react-select"
													value={fields.unpaid_employees  ? fields.unpaid_employees : null}
													options={employeesList}
													onChange={this.handleChange("unpaid_employees")}
													closeMenuOnSelect={true}
													onMenuOpen={this.handleMenuOpen}
													isMulti
													placeholder={<label htmlFor="unpaid_employees">{translate('calendar.select_employees')}</label>}
													filterOption={(option, inputValue) => {
														// Extract the label text if it's a JSX element, otherwise, use the string directly
														const labelText = typeof option.label === 'string' ? option.label : option.label.props.children;
														// Perform case-insensitive search
														return labelText.toLowerCase().includes(inputValue.toLowerCase());
													}}
												/>
												<span className="error text-danger">{this.state.errors["unpaid_employees"]}</span>
												<span className="error text-danger">{this.state.errors["unpaid_employee_id"]}</span>
											</div>
										</div>
									</div>
								:null}

								{isYard && fields ?
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="equipment" className="form-label">{translate('calendar.equipment')} </label>
												<Select
													key={random_key}
													className="custom-react-select"
													value={fields.equipment  ? fields.equipment : null}
													options={equipmentList}
													onChange={this.handleChange("equipment")}
													closeMenuOnSelect={true}
													onMenuOpen={this.handleEquipMenuOpen}
													isMulti
													placeholder={<label htmlFor="equipment">{translate('calendar.select_equipments')}</label>}
													filterOption={(option, inputValue) => {
														// Extract the label text if it's a JSX element, otherwise, use the string directly
														const labelText = typeof option.label === 'string' ? option.label : option.label.props.children;
														// Perform case-insensitive search
														return labelText.toLowerCase().includes(inputValue.toLowerCase());
													}}
												/>
												<span className="error text-danger">{this.state.errors["equipment"]}</span>
												<span className="error text-danger">{this.state.errors["equipment_id"]}</span>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="notes" className="form-label">{translate('calendar.description')} </label>
												<textarea type="notes" className="form-control" name='notes' id='notes' value={fields["notes"] && fields["notes"].value ? fields["notes"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "notes")}></textarea>
												<span className="error text-danger">{this.state.errors["notes"]}</span>
											</div>
										</div>
									</div>
								:null}
							</div>
							<div className='col-xl-12 d-flex'>
								<button type={submitted ? "button" :"submit"} className="btn btn-fill">
									{translate('system.submit')}{submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
								</button>
								<button type="button" className="btn btn-outline ms-1" onClick={() => this.closeModal()} data-dismiss="modal">
									{translate('system.close')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	}
}