import '@availity/block-ui/src/BlockUi.css';

import React, { Component} from "react";
import { translate, formatDate } from './../helpers/intl_helpers';
import { NavLink as Link } from "react-router-dom";
import { callBackendAPI, handleFormValidation,reFormatSuccess,handleFormInputs } from '../helpers/common';
import { STATUS_SUCCESS, GENERIC_TV_LOGINS, DATERANGE_DATE_TIME_FORMAT, DEPARTMENT_DROPDOWN}  from './../config/global_constants';
import BlockUi from '@availity/block-ui';
import Select from 'react-select';

import { UserDetails }  from '../UserDetails';


class EditProfile extends Component {
    static contextType = UserDetails;

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
			errors: {},
			fields: {},
			submitted: false,
            passRequired: false,
			isSystemAccountUser: false,
			locationList		: 	[],
        };

        this.handleFormInputs 			    =	this.handleFormInputs.bind(this);
		this.handleEditProfileFormSubmit 	=  	this.handleEditProfileFormSubmit.bind(this);

    }

    /**
	* Get initial details
	*/
	componentDidMount() {
		this.userDetails();
	}

	userDetails() {
		const { userDetails } = this.context;
		callBackendAPI([{model: 'employee', method: 'employeeDetails', employee_id: userDetails._id},{ model: 'home', method: 'masterList' , type: ["location"]}]).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				let userData = response.data[0].result || {};

				let equipDetails = userData.equipment_details || [];
				let equipList = [];
				if(equipDetails.length){
					equipDetails.map(val=>{
						equipList.push(val.equipment_code);
					})
				}

				let locationList  = response.data[1].result.location;

				let lctData 	= userData.location ? userData.location :[];
				let userDeprt 	= userData.department ? userData.department :[];

				let addedDepartments = new Set();
				let userDeprtList = [];
				let userLocationList = [];
				if(locationList.length && userDeprt.length){
					locationList.forEach(element=>{
						if(lctData.indexOf(element.value) !== -1){
							userLocationList.push({value: element.value ,label: element.label, department: element.department});

							element.department.forEach(item=>{
								if(userDeprt.indexOf(item) !== -1){
									let matchDepartment = DEPARTMENT_DROPDOWN.find(dept => dept.value === item);
									if (matchDepartment) {
										if (!addedDepartments.has(item)) {
											userDeprtList.push(matchDepartment);
											addedDepartments.add(item); // Mark the department as added
										}
									}
								}
							});
						}
					});
				}

				this.setState({
					locationList 		: response.data[1].result.location,
					fields: {
						location_id		: userLocationList,
						department_id	: userDeprtList,
						first_name		: {value: userData.first_name 		|| ""},
						last_name		: {value: userData.last_name 		|| ""},
						email			: {value: userData.email 			|| ""},
						mobile_number	: {value: userData.mobile 			|| ""},
						bpid 			: {value: userData.bpid 			|| ""},
						company_name 	: {value: userData.company_title 	|| ""},
						employee_number : {value: userData.employee_number 	|| ""},
						equipment_code 	: {value: equipList && equipList.length && equipList.join(',') 	|| ""},
						job_title 		: {value: userData.job_title_name 		|| ""},
						hire_date 		: {value: formatDate(userData.hire_date , DATERANGE_DATE_TIME_FORMAT) || ""},
						driver_tier_level_title 		: {value: userData.driver_tier_level_title 		|| ""},
					},
					isLoading: false,
					isSystemAccountUser: (userData && (userData.permission_level===GENERIC_TV_LOGINS)) ? true : false
				});
			}else {
				this.setState({ isLoading: true });
			}
		}).catch(err => console.log(err));
	}

	/** Manage input change event */
	handleChange = (fieldName) => (fieldVal) => {
		this.setState((prevState) => {
			const newFields = { ...prevState.fields, [fieldName]: fieldVal };

			// Reset department if location is changed
			if (fieldName === 'location_id') newFields.department_id = null;
			return { fields: newFields };
		});
	};

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fields !== this.state.fields) {
            this.updatePasswordRequired();
        }
    }

    updatePasswordRequired() {
        const { old_password, password, confirm_password } = this.state.fields;

        let passRequired = false;
        if ((old_password && old_password.value) || (password && password.value) || (confirm_password && confirm_password.value)) {
            passRequired = true;
        }
        this.setState({ passRequired });
    }

    /**
	* For manage form submit event
	*
	* @param e 			as 	current form object
	* @param formType	as 	form type (login, signup)
	*
	* @return null
	*/
	handleEditProfileFormSubmit(e){
		e.preventDefault();

		const {first_name,last_name,old_password, password, confirm_password , passRequired, department_id,location_id,mobile_number,bpid} = this.state.fields;

		let locationArr   	= [];
		let departmentArr   = [];

		if (location_id && location_id.length) locationArr = location_id.map(element => element.value);
		if (department_id && department_id.length) departmentArr = department_id.map(element => element.value);

        this.setState({
			submitted: true,
			fields : {
                ...this.state.fields,
                ...{
                    location: {value: locationArr.length ? locationArr : null},
                    department: {value: departmentArr.length ? departmentArr : null},
                }
            }
		},()=>{

            /** Api Requested Data **/
            let params = [{
                model				: 	'my_account',
                method				: 	'editProfile',
                first_name			: 	(first_name && first_name.value)                ? first_name.value       :"",
                last_name			: 	(last_name && last_name.value)	                ? last_name.value	     :"",
                old_password	    : 	(old_password && old_password.value)            ? old_password.value	 :"",
                password    	    : 	(password && password.value) 		            ? password.value		 :"",
                confirm_password    : 	(confirm_password && confirm_password.value) 	? confirm_password.value :"",
				mobile_number		:	(mobile_number && mobile_number.value) 			?	mobile_number.value  :"",
				bpid 				: 	(bpid && bpid.value) 							? 	bpid.value 			 : "",
				location			:	locationArr,
				department			:	departmentArr,
            }];


            /** Validation Rules **/
            let validations = {
                first_name	: {label: translate('first_name','First Name'), "rules" :["required"]},
                last_name	: {label: translate('last_name','Last Name'), "rules" :["required"]},
				mobile_number		: {"label": translate('mobile_number','Phone Number'), "rules" :["required"]},
				location	: {label: translate('employee.location'), "rules": ["select"] },
				department	: {label: translate('employee.department'), "rules": ["select"] },
            }

            if(passRequired){
                validations.old_password        =	{"label": translate('user.current_password','Current Password'), "rules" :["required"]};
                validations.password            =	{"label": translate('user.new_password','New Password'), "rules" :["required","minLength"]};
                validations.confirm_password    =	{"label": translate('user.confirm_password','Confirm Password'), "rules" :["required","minLength","equal"]};
            }

            /** Handle validation and api response **/
            handleFormValidation(validations,this.state.fields, params).then(response =>{
                this.setState({submitted: false});

                if (!response.success) {
                    this.setState({ errors: response.errors });
                    return;
                }

                reFormatSuccess(response.data.message[0].msg);
                this.setState({ submitted: false, errors: {} },()=>{
					this.userDetails();
				});

            }).catch(err => console.log(err));
        });

	}

    /**
	* For update state fileds values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response = handleFormInputs(e, field, this.state.fields);
		this.setState(response, () => {
            this.updatePasswordRequired();
        });
	}// end handleFormInputs()

    render() {
		const { submitted, fields, errors, isLoading, passRequired, isSystemAccountUser, locationList} = this.state;

        return <>
			<BlockUi tag="div" blocking={isLoading}>
				<div className="dashboard-content">
					<div className="dashboard-heading d-flex justify-content-between align-items-center">
						<div className="left-heading">
							<h1>{translate("user.update_profile")}</h1>
						</div>
					</div>
					<div className="add-employee">
						<form className="adduser-form" onSubmit={(event) => this.handleEditProfileFormSubmit(event, 'addUser')} noValidate>
							<div className="row ">
								<div className="col-6">
									<div className="form-floating">
										<input
											id="first_name"
											type="text"
											name="first_name"
											className="form-control"
											placeholder={translate("user.first_name")}
											value={fields["first_name"] ? fields["first_name"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "first_name")}
										/>
										<span className="error text-danger">{errors["first_name"]}</span>
										<label htmlFor="first_name">{translate("user.first_name")}<span className="error text-danger">*</span></label>
									</div>
								</div>
                                <div className="col-6">
									<div className="form-floating">
										<input
											id="last_name"
											type="text"
											name="last_name"
											className="form-control"
											placeholder={translate("user.last_name")}
											value={fields["last_name"] ? fields["last_name"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "last_name")}
										/>
										<label htmlFor="last_name">{translate("user.last_name")}<span className="error text-danger">*</span></label>
										<span className="error text-danger">{errors["last_name"]}</span>
									</div>
								</div>
							</div>
							<div className="row ">
								<div className="col-6">
									<div className="form-floating">
										<div className="custom-form-group form-group">
											<Select
												className="custom-react-select"
												placeholder={<label htmlFor="location">{translate("employee.location")}<span className="error text-danger">*</span></label>}
												options={locationList}
												value={fields.location_id ? fields.location_id : null}
												closeMenuOnSelect={true}
												isMulti
												onChange={this.handleChange("location_id")}
											/>
										</div>
										<span className="error">{errors["location"]}</span>
									</div>
								</div>
								<div className="col-lg-6">
									<div className='form-floating'>
										<label htmlFor="department" className="form-label">{translate('calendar.department')} <span className="error text-danger">*</span></label>
										<div className="custom-form-group form-group">
											<Select
												className="custom-react-select"
												value={fields.department_id ? fields.department_id : null}
												options={(fields.location_id) ? DEPARTMENT_DROPDOWN.filter(dept => fields.location_id && fields.location_id.some(loc => loc.department.includes(dept.value))) : []}
												onChange={this.handleChange("department_id")}
												closeMenuOnSelect={true}
												isMulti
												placeholder={<label htmlFor="department">{translate("employee.department")}<span className="error text-danger">*</span></label>}
											/>
										</div>
										<span className="error text-danger">{this.state.errors["department"]}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-floating">
										<input
											id="mobile_number"
											type="text"
											className="form-control"
											placeholder={translate("subcontractor.mobile_number")}
											defaultValue={fields["mobile_number"] ? fields["mobile_number"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "mobile_number")}
										/>
										<span className="error text-danger">{errors["mobile_number"]}</span>
										<label htmlFor="mobile_number">{translate("employee.mobile_number")}<span className="error text-danger">*</span></label>
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<input
											id="bpid"
											type="text"
											name="bpid"
											className="form-control"
											placeholder={translate("employee.bpid")}
											defaultValue={fields["bpid"] ? fields["bpid"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "bpid")}
										/>
										<span className="error text-danger">{errors["bpid"]}</span>
										<label htmlFor="bpid">{translate("employee.bpid")}</label>
									</div>
								</div>
							</div>
							<div className="row ">
								<div className="col-6">
									<div className="form-floating">
										<input
											id="email"
											type="text"
											name="email"
											className="form-control"
											placeholder={translate("user.email")}
											value={fields["email"] ? fields["email"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "email")}
                                            disabled={true}
										/>
										<label htmlFor="email">{translate("user.email")}</label>
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<input
											id="employee_number"
											type="text"
											name="employee_number"
											className="form-control"
											placeholder={translate("employee.employee_number")}
											defaultValue={fields["employee_number"] ? fields["employee_number"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "employee_number")}
											disabled={true}
										/>
										<span className="error text-danger">{errors["employee_number"]}</span>
										<label htmlFor="employee_number">{translate("employee.employee_number")}</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-floating">
										<input
											id="job_title"
											type="text"
											name="job_title"
											className="form-control"
											placeholder={translate("employee.bpid")}
											defaultValue={fields["job_title"] ? fields["job_title"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "job_title")}
											disabled={true}
										/>
										<span className="error">{errors["job_title"]}</span>
										<label htmlFor="job_title">{translate("employee.job_title")}</label>
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<input
											id="company_name"
											type="text"
											name="company_name"
											className="form-control"
											placeholder={translate("employee.company_name")}
											defaultValue={fields["company_name"] ? fields["company_name"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "company_name")}
											disabled={true}
										/>
										<span className="error">{errors["company_name"]}</span>
										<label htmlFor="company_name">{translate("employee.company_name")}</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-floating">
										<input
											id="hire_date"
											type="text"
											name="hire_date"
											className="form-control"
											placeholder={translate("employee.bpid")}
											defaultValue={fields["hire_date"] ? fields["hire_date"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "hire_date")}
											disabled={true}
										/>
										<span className="error">{errors["hire_date"]}</span>
										<label htmlFor="hire_date">{translate("employee.hire_date")}</label>
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<input
											id="driver_tier_level"
											type="text"
											name="driver_tier_level"
											className="form-control"
											placeholder={translate("employee.driver_tier_level")}
											defaultValue={fields["driver_tier_level_title"] ? fields["driver_tier_level_title"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "driver_tier_level")}
											disabled={true}
										/>
										<span className="error">{errors["driver_tier_level"]}</span>
										<label htmlFor="driver_tier_level">{translate("employee.driver_tier_level")}</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-floating">
										<input
											id="equipment"
											type="text"
											name="equipment"
											className="form-control"
											placeholder={translate("employee.equipment")}
											defaultValue={fields["equipment_code"] ? fields["equipment_code"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "equipment")}
											disabled={true}
										/>
										<span className="error">{errors["equipment"]}</span>
										<label htmlFor="equipment">{translate("employee.assigned_vehicle")}</label>
									</div>
								</div>
							</div>
							{(isSystemAccountUser) &&
								<div className="row">
									<div className="col-12">
										<div className="form-floating">
											<input
												id="current_password"
												type="password"
												name="current_password"
												className="form-control"
												placeholder={translate("user.current_password")}
												value={fields["old_password"] ? fields["old_password"]["value"] : ""}
												onChange={(event) => this.handleFormInputs(event, "old_password")}
											/>
											<span className="error text-danger">{errors["old_password"]}</span>
											<label htmlFor="current_password">{translate("user.current_password")}{passRequired && <span className="error text-danger">*</span>}</label>
										</div>
									</div>
									<div className="col-12">
										<div className="form-floating">
											<input
												id="new_password"
												type="password"
												name="new_password"
												className="form-control"
												placeholder={translate("user.new_password")}
												value={fields["password"] ? fields["password"]["value"] : ""}
												onChange={(event) => this.handleFormInputs(event, "password")}
											/>
											<span className="error text-danger">{errors["password"]}</span>
											<label htmlFor="new_password">{translate("user.new_password")}{passRequired && <span className="error text-danger">*</span>}</label>
										</div>
									</div>
									<div className="col-12">
										<div className="form-floating">
											<input
												id="confirm_password"
												type="password"
												name="confirm_password"
												className="form-control"
												placeholder={translate("user.confirm_password")}
												value={fields["confirm_password"] ? fields["confirm_password"]["value"] : ""}
												onChange={(event) => this.handleFormInputs(event, "confirm_password")}
											/>
											<span className="error text-danger">{errors["confirm_password"]}</span>
											<label htmlFor="confirm_password">{translate("user.confirm_password")}{passRequired && <span className="error text-danger">*</span>}</label>
										</div>
									</div>
								</div>
							}
							<div className="employee-submit d-flex flex-wrap align-items-center">
								<button type={submitted ? "button" : "submit"} className="btn btn-fill">
									{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
								</button>
                                <div>
									<Link to="/" className="btn btn-outline">{translate("system.cancel")}</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</BlockUi>

        </>
    }
}
export default EditProfile