import '@availity/block-ui/src/BlockUi.css';
import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from "react";
import DatePicker from "react-datepicker";
import BlockUi from '@availity/block-ui';

import { callBackendAPI, handleFormValidation,handleFormInputs, CustomInput} from '../../helpers/common';
import {STATUS_SUCCESS, CALENDAR_DATE_TIME_FORMAT}  from '../../config/global_constants';
import { translate, formatDate  } from '../../helpers/intl_helpers';

export default class AddEditForm extends Component {

	constructor(props) {
        super(props)

        this.state = {
			isLoading		:	true,
			errors			:	{},
			fields			:	{},
			submitted		: 	false,
			jobNumberList	:	[]
        };

		this.closeModal 				=	this.closeModal.bind(this);
		this.handleFormInputs 			=	this.handleFormInputs.bind(this);
		this.handleScheduleFormSubmit 	=  	this.handleScheduleFormSubmit.bind(this);
		this.fetchDataForEdit 			=  	this.fetchDataForEdit.bind(this);
		this.handleChange 				=	this.handleChange.bind(this);
	}

	/** Call a function for get schedule details for Edit Form */
	componentDidMount() {
		this.fetchDataForEdit(this.props.scheduleId);
    }

	/** Call a function for get schedule details for Edit Form */
	componentDidUpdate(prevProps) {
        if (this.props.scheduleId !== prevProps.scheduleId) {
            this.fetchDataForEdit(this.props.scheduleId);
        }
    }

	/** For get schedule details for Edit Form */
	fetchDataForEdit(id){
		window.document.body.classList.add("modal-open");
		window.document.body.classList.add("overflow-hidden");

		if(id){
			/** Get  schedule details */
			callBackendAPI([{ model: 'schedule', method: 'getDetails', schedule_id : id }]).then((response) => {
				console.log("response",response)
					if(response.success && response.data[0].status === STATUS_SUCCESS){
						let data = response.data[0].result || {};

						/** Update fields */
						this.setState({
							isLoading :false,
							fields: {
								schedule_id	: {value: data._id   		?	data._id		:"" },
								date		: {value: data.date	 		?	data.date		:"" },
								title		: {value: data.title 		?	data.title		:"" },
								description	: {value: data.description 	?	data.description:"" },
							}
						});
					}else{
						this.closeModal();
					}
			}).catch(err => console.log(err));
		}else{
			this.setState({ isLoading: false});
		}
	}//editForm

	/**
	* For Set stats for select box selected value
	*
	* @param field	as 	selected name
	* @param value	as 	selected value
	*
	* @return null
	*/
	handleChange = (field) =>(value)=>{
		this.setState({ fields : {...this.state.fields, ...{[field]: value }} });
	}

	/**
	* For manage form submit event
	*
	* @param e 			as 	current form object
	* @param formType	as 	form type (login, signup)
	*
	* @return null
	*/
	handleScheduleFormSubmit(e){
		e.preventDefault();

		/** Update submit loader stats */
		this.setState({submitted: true},()=>{

			const { title , date, description, schedule_id } = this.state.fields;

			/** Api Requested Data **/
			let params = [{
				model			: 	'schedule',
				method			: 	'addEdit',
				schedule_id		: 	(schedule_id && schedule_id.value)	?	schedule_id.value	:"",
				date			:	(date && date.value) 				?	formatDate(date.value,CALENDAR_DATE_TIME_FORMAT) :"",
				title			: 	(title && title.value)			  	? 	title.value 		:"",
				description		: 	(description && description.value)	? 	description.value 	:""
			}];

			/** Validation Rules **/
			let validations = {
				date	: {label: translate('schedule.date'), "rules": ["required"] },
				title	: {label: translate('schedule.subject'), "rules" :["required"]},
			}

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params, true, true, true).then(response =>{
				this.setState({submitted: false});

				if(!response.success) return this.setState({ errors : response.errors});

				/** Call the function to fetch list */
				if(this.props.refreshDataTable) {
					this.props.refreshDataTable();
				}

				/** Close the modal */
				this.closeModal(false);

				/** Remove disable attribute from submit button */
				this.setState({ errors: {} , fields: {} , submitted: false});
			}).catch(err => console.log(err));
		});
	}

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
		window.document.body.classList.remove("modal-open");
		window.document.body.classList.remove("overflow-hidden");
		if(this.props.toggleModal) this.props.toggleModal(false);
	}// end closeModal()

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	render() {
		const {submitted , fields , errors, isLoading} = this.state;
        const isFormVisible = this.props.isFormVisible;
		const scheduleId 	= (fields && fields.schedule_id && fields.schedule_id.value) ?  fields.schedule_id.value :"";

		return <>
			<div className={"modal fade mt-5 "+(isFormVisible ? "show d-block" :"")} id="adduserModal" tabIndex={-1} aria-labelledby="adduserModal" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.closeModal()} >
							X {translate("system.close")}
						</button>
						<div className="modal-body">
							<BlockUi tag="div" blocking={isLoading}>
								<h1 className="modal-title">{scheduleId ? translate("schedule.edit_schedule") : translate("schedule.add_schedule")}</h1>
								<div className="adduser-form">
									<form className="workOrder-form" onSubmit={(event) => this.handleScheduleFormSubmit(event, 'workOrder')} noValidate>
										<div className="row">
											<div className="col-12">
												<div className="form-floating">
													<div className="custom-form-group form-group">
														<DatePicker
															className="form-control"
															selected={fields.date && fields.date.value ? fields.date.value :null}
															onChange={(date) => {this.setState({ fields : {...fields, ...{date:{value: date} }} }) }}
															placeholderText={translate("schedule.select_date")+"*"}
															dropdownMode="select"
															showMonthDropdown
															showYearDropdown
															customInput={<CustomInput />}
															isClearable
														/>
													</div>
													<span className="error">{errors["date"]}</span>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div className="form-floating">
													<input
														id="title"
														type="text"
														className="form-control"
														placeholder={translate("schedule.subject")}
														value={ this.state.fields["title"] ? this.state.fields["title"]["value"] : ""}
														onChange={(event) => this.handleFormInputs(event, "title")}
													/>
													<span className="error text-danger">{errors["title"]}</span>
													<label htmlFor="title">{translate("schedule.subject")}<span className="error text-danger">*</span></label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div className="form-floating h-100">
													<textarea
														id="description"
														className="form-control h-100"
														placeholder={translate("schedule.description")}
														onChange={(event) => this.handleFormInputs(event, "description")}
														defaultValue={ this.state.fields["description"] ? this.state.fields["description"]["value"] : ""}
													></textarea>
													<span className="error text-danger">{errors["description"]}</span>
													<label htmlFor="description">{translate("schedule.description")}</label>
												</div>
											</div>
										</div>

										<button type={submitted ? "button" :"submit"} className="btn btn-fill form-control width-initial mt-3">
											{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
										</button>
									</form>
								</div>
							</BlockUi>
						</div>
					</div>
				</div>
			</div>
			{isFormVisible && <div className="modal-backdrop fade show"></div> }
		</>
	}
}

